.puam-sidebar-left,
.puam-sidebar-right {
  .puam-layouts-sidebar {
    .pane-content {
      background: $gray-lgt2;
      padding: 1em;
      margin-bottom: 2em;
    }
  }

  article {
    margin-bottom: 1.5em;
    position: relative;

    &.story {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;

      .img-right {
        margin: 0 0 0 1em;
      }

      .story__meta {
        flex-grow: 1;
      }
    }
  }
}

.page-calendar {
  .puam-sidebar-left,
  .puam-sidebar-right {
    .puam-layouts-sidebar {
      .pane-content {
        background: $white;
        padding: 0 1em 0 0;
      }
    }
  }
}

/* Styling for MHV page, which can be used in the future */
.view-microsite-related-content {
  .views-row {
    align-items: center;
    clear: both;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    a:hover ~ .thumbnail--circle img,
    a:focus ~ .thumbnail--circle img {
    }

    a {
      &:hover,
      &:focus {
        ~ .field-name-field-thumbnail,
        ~ .thumbnail {
          img {
            border: 4px solid $primary-brand;
            padding: 7px;
          }
        }
      }
    }

    > .field-name-field-thumbnail,
    > .thumbnail {
      margin-right: 1em;
      max-width: 75px;

      @media all and (min-width: $bp-large) {
        max-width: 150px;
      }

      img {
        border: 0;
        @include border-top-radius(50%);
        @include border-right-radius(50%);
        @include border-bottom-radius(50%);
        @include border-left-radius(50%);
        float: left;
        margin: 0 1em 1.5em 0;
        max-width: 100%;
        padding: 0;
        transition: padding 0.5s ease-out, border-color 0.5s ease-out;

        @media all and (min-width: $bp-large) {
          max-width: 150px;
        }
      }

      &:hover img,
      &:focus img {
        border: 4px solid $primary-brand;
      }
    }

    .container {
      flex-basis: calc(100% - 75px - 1em);
      padding: 0;

      @media all and (min-width: $bp-large) {
        flex-basis: calc(100% - 150px - 1em);
      }
    }

    h3 {
      font-size: 1rem;
    }

    .field-name-field-teaser {
      display: none;
    }

    .meta {
      flex-basis: 50%;
      flex-grow: 1;
      font-weight: bold;

      &--date {
        flex-basis: 100%;
        flex-grow: 1;
        font-size: 1.5em;
        font-weight: bold;
        order: -1;
      }
    }
  }
}
