/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox,#cboxOverlay,#cboxWrapper {
  position:absolute;
  top:0;
  left:0;
  z-index:9999;
  overflow:hidden
}

#cboxWrapper {
  max-width:none
}

#cboxOverlay {
  position:fixed;
  width:100%;
  height:100%
}

#cboxMiddleLeft,#cboxBottomLeft {
  clear:left
}

#cboxContent {
  position:relative
}

#cboxLoadedContent {
  overflow:auto;
  -webkit-overflow-scrolling:touch
}

#cboxTitle {
  margin:0
}

#cboxLoadingOverlay,#cboxLoadingGraphic {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%
}

#cboxPrevious,#cboxNext,#cboxClose,#cboxSlideshow {
  cursor:pointer
}

.cboxPhoto {
  float:left;
  margin:auto;
  border:0;
  display:block;
  max-width:none;
  -ms-interpolation-mode:bicubic
}

.cboxIframe {
  width:100%;
  height:100%;
  display:block;
  border:0;
  padding:0;
  margin:0
}

#colorbox,#cboxContent,#cboxLoadedContent {
  box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background: $black;
}

#colorbox {
  outline:0;
  position: fixed !important;
}

#cboxTopLeft,
#cboxTopRight,
#cboxBottomLeft,
#cboxBottomRight,
#cboxMiddleLeft,
#cboxMiddleRight,
#cboxTopCenter,
#cboxBottomCenter {
  display: none;
}

#cboxContent {
  background:transparent;
  overflow:hidden;

  img {
    margin: 0 auto;
  }
}

.cboxIframe {
  background:#fff
}

#cboxError {
  padding:50px;
  border:1px solid #ccc
}

#cboxLoadedContent {
  margin-bottom:28px
}

#cboxCurrent {
  position:absolute;
  bottom:4px;
  right: 20px;
  text-align:right;
  width:10%;
  color:#ffffff;
}
#cboxTitle {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 90%;
  color: #ffffff;
}

#cboxCurrent {
  position:absolute;
  bottom:4px;
  right:20px;
  color:#ffffff;
}

#cboxLoadingOverlay {
  background:url(../images/cbox_loading_background.png) no-repeat center center
}

#cboxLoadingGraphic {
  background:url(../images/cbox_loading.gif) no-repeat center center
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious,#cboxNext,#cboxSlideshow,#cboxClose {
  border:0;
  padding:0;
  margin:0;
  overflow:visible;
  width:auto;
  background:none
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,#cboxNext:active,#cboxSlideshow:active,#cboxClose:active {
  outline:0
}

#cboxSlideshow {
  position:absolute;
  bottom:4px;
  right:30px;
  color:#0092ef
}

#cboxPrevious{
  &:before {
    content: '\e808';
    color: #ffffff;
    font-family: "puamicons";
    font-size: 40px;
    position:absolute;
    top:50%;
    left:10px;
    background:none;
    width:auto;
    height:auto;
    text-indent:0;
    opacity: .8;
  }
  &:hover,
  &:focus {
    background-position:-75px -25px
  }
}

#cboxNext{
  &:before {
    content: '\e807';
    color: #ffffff;
    font-family: "puamicons";
    font-size: 40px;
    position:absolute;
    top:50%;
    right:10px;
    background:none;
    width:auto;
    height:auto;
    text-indent:0;
    opacity: .8;
  }
  &:hover,
  &:focus {
    background-position:-50px -25px
  }
}

#cboxClose {
  position: absolute;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  opacity: 1;

  &:hover,
  &:focus {
    opacity: 1;
  }
}


/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,.cboxIE #cboxTopCenter,.cboxIE #cboxTopRight,.cboxIE #cboxBottomLeft,.cboxIE #cboxBottomCenter,.cboxIE #cboxBottomRight,.cboxIE #cboxMiddleLeft,.cboxIE #cboxMiddleRight {
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)
}
