//Styling for Ar Object Pages
.puam-object-viewer {
  position: relative;
  text-align: center;
}

/* Images */
.slick-object {
  &.slick-slider {
    margin-bottom: 0.5em;
  }

  .tms-media-image {
    margin-bottom: 1em;
    min-height: 300px;

    img {
      margin: 0 auto;
      max-height: 300px;
      max-width: 300px;
      width: auto;
    }
  }

  //.slick-track {
  //  min-width: 100%;
  //}
  //
  //.slick-slide {
  //  min-width: 100%;
  //}
}

.slick-objectnav {
  margin-top: 1em;

  img {
    border: 4px solid transparent;
    margin: 0 auto;
    padding: 0.5rem;
  }

  .slick-current img {
    border: 4px solid $primary-brand;
  }

  //.slick-track {
  //  min-width: 100%;
  //}
  //
  //.slick-slide {
  //  min-width: 33%;
  //}

  li:first-child:last-child {
    display: none;
  }
}

.object-action {
  font-size: 2rem;

  &.full-page-launcher {
    font-size: 2rem;
    left: 0;
    position: absolute;
    text-align: right;
    top: 312px;
    width: 47%;
  }

  //&.iiif-launcher {
  //  font-size: 2rem;
  //  position: absolute;
  //  right: 0;
  //  text-align: left;
  //  top: 312px;
  //  width: 47%;
  //}
}

/* OpenSeadragon Styling */
.puam-iiif-toolbar {
  display: none;
  min-height: 2.5em;
  padding: 0.5em;
  visibility: hidden;

  &.fullpage {
    background: $white;
    bottom: 0;
    display: block;
    left: 0;
    position: absolute !important;
    right: 0;
    visibility: visible;
    z-index: 1000001;
  }
}

.iiif-controls {
  text-align: center;

  li {
    display: inline;
    font-size: 2rem;
  }

  a {
    cursor: pointer;

    &:hover,
    &:focus {
      color: $primary-brand;
    }

    span {
      display: inline-block;
      text-indent: -9999px;
      width: 0;
    }
  }
}

.iiif-download {
  display: inline;

  li a {
    display: none;

    &.active {
      display: inline;
    }
  }
}

.iiif-previous,
.iiif-next {
  background: $white;
  cursor: pointer;
  font-size: 2em;
  position: absolute !important;
  top: 40%;
  z-index: 1;

  &[style*="opacity: 0.2"] {
    display: none !important;
  }

  &:hover,
  &:focus {
    color: $primary-brand;
  }

  span {
    display: inline-block;
    text-indent: -9999px;
    width: 0;
  }
}

.iiif-previous {
  left: 0;
}

.iiif-next {
  right: 0;
}

.puam-iiif-viewer {
  display: none;
  position: relative;
  visibility: hidden;

  &.fullpage {
    display: block;
    visibility: visible;
    z-index: 1000000;
  }

  .openseadragon-container {
    min-height: 400px;
  }

  canvas {
    cursor: move;
  }
}

.object-label,
.object-tombstone--label {
  font-weight: 400;
  margin: 0;
}

.object-notes--edit,
.object-notes--view {
  margin: 1em 0;
}

/* About the Object */
.object-page--about {
  h3.delta {
    margin-bottom: 0;
  }
}

.views-field-textentryhtml,
.view-campus-voices {
  margin: 1em 0;

  p {
    > span[lang] > br,
    + br {
      display: none;
    }
  }
}

/* Browse Related */
a.related-term {
  margin-right: 2px;

  &:after {
    content: ",";
  }

  &:last-child:after {
    content: "";
  }
}

/* Colorbox overrides */
.page-collections-objects {
  #cboxOverlay {
    background: transparent;
  }

  #colorbox {
    background: $gray-lgt2;
    border-left: 4px solid $black;
    border-top: 4px solid $black;
    bottom: 0;
    left: auto !important;
    max-height: 350px;
    right: 0;
    top: auto !important;
  }

  #cboxClose {
    color: $error;
  }

  .filter-wrapper {
    display: none;
  }
}

.iiif-launcher {
  background-image: url("/sites/all/themes/puam_modern/images/iiif-logo.png");
  background-image: url("/sites/all/themes/puam_modern/images/iiif-logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin-right: 0.4em;
  height: 2rem;
  vertical-align: -6px;
  width: 2rem;
}
