.tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;

  li {
    border-bottom: 1px solid $gray-lgt1;
    border-right: 1px solid $gray-lgt1;
    display: flex;
    flex-grow: 1;
    text-align: center;

    &:last-child {
      border-right: 0;
    }
  }

  a {
    background: $gray;
    display: block;
    flex-grow: 1;
    padding: $pad-half;

    &:hover,
    &:focus {
      background: $gray-lgt1;
    }

    &.active {
      background: $gray-drk2;
      color: $white;
    }
  }
}
