.highlight {
  background-color: $orange-lgt1;
  padding: 20px;
  position: relative;

  a {
    display: inline-block;
    text-decoration: none;
    width: 100%;

    &:hover ~ .title,
    &:focus ~ .title {
      color: $primary-brand;

      &::after {
        //content: '\e806';
        transform: rotate(90deg) translateX(5%);
        transition: transform 0.35s ease-in-out;
      }
    }
  }

  h2.btn > a {
    width: auto;
  }

  .title {
    &::after {
      font-family: "puamicons";
      font-style: normal;
      font-weight: normal;
      speak: none;
      font-size: 1.2em;

      display: inline-block;
      text-decoration: inherit;
      width: 2.5rem;
      text-align: center;
      /* opacity: .8; */

      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;

      /* fix buttons height, for twitter bootstrap */
      line-height: 1.2em;

      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      margin: 0;

      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */

      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      content: "\e807";
    }

    &:hover,
    &:focus {
      background: transparent;
      color: $primary-brand;
      text-decoration: none;

      &::after {
        transform: rotate(90deg) translateX(5%);
        transition: transform 0.35s ease-in-out;
      }
    }
  }

  p > a {
    display: inline;
    width: auto;
  }

  img {
    margin-bottom: 10px;
    width: 100%;
  }
}
