/*------------------------------------*\
    $GLOBAL CLASSES
\*------------------------------------*/

/* Clearfix */
.cf {
  *zoom: 1;
}
.cf:before,
.cf:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.cf:after {
  clear: both;
}

/* Completely remove from the flow and screen readers. */
.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

/* Completely remove from the flow but leave available to screen readers. */
.is-vishidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Floats */
.right {
  float: right;
  padding: 0 0 $pad $pad;
}

.right-search {
  float: right;
  padding: 0 0 $pad 0;
}

.left {
  float: left;
  padding: 0 $pad $pad 0;
}

/* Text-Align */
.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

/* Display Classes */
.hide-small {
  @media all and (max-width: $bp-med) {
    display: none;
  }
}

.hide-med {
  @media all and (min-width: $bp-med) and (max-width: $bp-large) {
    display: none;
  }
}

.hide-large {
  @media all and (min-width: $bp-large) {
    display: none;
  }
}

//States
.valid {
  color: $valid;
}

.error {
  color: $error;
}

.warning {
  color: $warning;
}

.information {
  color: $information;
}

.font-secondary {
  font-family: $font-secondary;
}

.meta {
  font-size: $font-size-med;
}

.meta--bold {
  @include font-bold;
}

.container {
  padding: 20px;

  &--short {
    padding-bottom: 0;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &--wide {
    padding-left: 0;
    padding-right: 0;
  }

  &--max {
    margin: 0 auto 1rem;

    @media all and (min-width: 980px) {
      margin: 0 calc((100vw - 980px) / 2) 1rem;
    }

    + .container--max {
      margin-top: 2rem;
    }
  }
}

.element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
