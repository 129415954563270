/* Text-Related Elements */
p {
  margin-bottom: $space;
}

.lead {
  font-weight: 300;
  font-size: 1.2rem;
  margin: $space-extra 0;

  + p:last-child {
    margin-bottom: 1.5em;
  }
}

a {
  color: $black;
  text-decoration: none;
  padding-bottom: 1px;
  @include transition(all 0.25s ease-out);

  &:hover,
  &:focus {
    color: $gray-drk1;
    @include transition(all 0.25s ease-out);
  }
}

p,
div.object-tombstone,
.region-content li,
.region-content h2:not(.btn),
.region-content h3:not(.btn) {
  a {
    border-bottom: 2px solid $primary-brand;
    font-weight: 400;
    word-wrap: break-word;

    &:hover,
    &:focus {
      border-bottom: 1px solid $gray-drk1;
    }

    &.mask,
    &.btn {
      border-bottom: 0;

      &:hover,
      &:focus {
        border-bottom: 0;
      }
    }
  }

  .controls a,
  ul.menu a {
    border-bottom: 0;
  }
}

.font--light {
  font-weight: 300;
}

.intro {
  color: $white;
}

/* Blockquote */
blockquote {
  //font-style:italic;
  color: $black;
  margin-bottom: $space;
  padding-left: $pad;
  padding-right: $pad;
  @include font-light;
}

time {
  @include font-light;
}

/* Horizontal Rule */
hr {
  background: $gray-lgt2;
  border: 0;
  height: 3px;
  margin: $space-double 0;
}

abbr {
  border-bottom: 1px dotted $gray;
  cursor: help;
}

/* Text Alignment Helper Classes */
.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}
