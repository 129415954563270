//.pane-category-picker,
//.pane-daterangepicker {
//  @include span-columns(6);
//  color: $white;
//  border: 5px solid $black;
//
//  &:hover {
//    border-color: $primary-brand;
//
//    >h2 {
//      background: $primary-brand;
//    }
//  }
//
//  >h2 {
//    background: $black;
//    cursor: pointer;
//    font-size: 14pt;
//    text-transform: uppercase;
//  }
//
//  .pane-content {
//    display: none;
//  }
//
//  ul {
//    padding: 0;
//    margin: 0;
//  }
//
//  li {
//    list-style: none;
//
//    img {
//      display: none;
//    }
//
//    a {
//      padding: 0.5em .5em 0.5em 1em;
//      border-bottom: 1px solid $gray-drk2;
//      text-decoration: none;
//      display: block;
//      color: $black;
//      font-weight: bold;
//
//      &.active {
//        background: $gray-drk1;
//      }
//
//      &:hover {
//        background: $gray-drk1;
//      }
//    }
//
//    &:last-child a {
//      border-bottom: 0;
//    }
//  }
//
//  label {
//    display: none;
//  }
//
//  input {
//    border: 3px solid $gray;
//    font-weight: bold;
//    padding: .5em;
//    width: calc(100% - 1em);
//    margin: .5em;
//  }
//}
//
//.pane-daterangepicker {
//  float: right;
//  margin-right: 0;
//}
//
//.ui-daterangepickercontain {
//  margin-top: -370px;
//}
//
//.ui-widget-content {
//  border: 0;
//  border-radius: 0;
//  background: $gray-lgt3;
//}
//
//.ui-daterangepicker .ranges {
//  margin-left: 0;
//}

.event-list {
  clear: both;

  @media (min-width: $bp-med-2) {
    border-right: 4px solid $gray-lgt2;
  }

  .views-exposed-widgets {
    margin-bottom: .5em;

    label {
      display: inline-block;
      background: transparent;
      color: $black;
      line-height: 1;
      border: 0;
      text-align: center;
      letter-spacing: 0.1em;
      font-family: "Lato", Georgia, Times, "Times New Roman", serif;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 900;
      font-size: 1.1875em;
      margin: 0;
      padding: .85em 0 0.75rem 0;
    }

    .description {
      display: none;
    }
  }

  .views-row {
    margin-bottom: 1.5em;
    padding-right: 1em;
  }

  img {
    border: 5px solid $gray-lgt1;
  }

  a:hover,
  a:focus {
    color: $black;

    h4 {
      color: $primary-brand;
    }
  }

  h4 {
    font-size: $font-size-aa;
  }

  h3 {
    border-top: 4px solid $gray-lgt2;
    margin-top: 1em;
    padding: 1em 0;
  }

  .details {
    margin-top: 1em;
  }

  @media (min-width: $bp-med) {
    .thumbnail {
      float: left;
      margin-right: 1em;
      width: 216px;
    }

    .details {
      float: left;
      margin-top: 0;
      width: calc(100% - 216px - 1em);
    }
  }
}

.micro-list {
  padding: 20px;

  @media (min-width: $bp-med) {
    padding: 0;
  }

  .controls h2 {
    padding-left: 0;
  }

  li {
    margin-bottom: 1em;
    position: relative;
  }

  .date-repeat-instance {
    display: inline;
  }

  img {
    width: 100%;
  }
}
