.tiles {
  clear: both;

  .field-item,
  li {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }

  img {
    width: 100%;
  }

  a  {
    ~ {
      h2, h3, .container h3, .title {
        display: inline-block;
        border-bottom: 2px solid $primary-brand;
    }
  }
  &:hover ~,
  &:focus ~ {
    h2, h3, .container h3, .title {
      border-bottom-color: $gray-drk1;
      color: $primary-brand;
    }
  }
}

  .date-repeat-instance {
    display: inline;
  }

  &--center {
    .field-items,
    ul {
      justify-content: center;
    }
  }
}

@media (min-width: $bp-small-2) {
  .tiles {
    .pane-content > .field > .field-items,
    > .field > .field-items,
    &:not(.tiles--buttons) ul:not(.pager) {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      list-style: none;
      padding-left: 0;

      > .field-item,
      li {
        margin-bottom: 0;
      }
    }

    &.tiles--border-off {
      .pane-content > .field > .field-items,
      > .field > .field-items,
      &:not(.tiles--buttons) ul:not(.pager) {
        > .field-item,
        li {
          border-right: 0 !important;
          border-top: 0 !important;
        }
      }
    }
  }
}

.tiles--third,
.puam-onecol .tiles {
  .pane-content > .field > .field-items,
  > .field > .field-items,
  &:not(.tiles--buttons) ul:not(.pager) {
    > .field-item,
    li {
      flex-grow: 1;

      @media (min-width: $bp-small-2) and (max-width: $bp-large-2 - 0.001) {
        max-width: calc(49% - 6px);
        width: calc(49% - 6px);
      }

      @media (min-width: $bp-large-2) {
        max-width: calc(33.333% - 14px);
        width: calc(33.333% - 14px);
      }
    }
  }

  @media all and (min-width: $bp-small-2) {
    .img-left {
      + .meta {
        @include span-columns(7);
      }
    }
  }
}

.puam-sidebar-left .tiles:not(.tiles--third),
.puam-sidebar-right .tiles,
.tiles--half,
.puam-onecol .tiles.tiles--half {
  .pane-content > .field > .field-items,
  > .field > .field-items,
  &:not(.tiles--buttons) ul:not(.pager) {
    justify-content: space-between;

    > .field-item,
    li {
      flex-grow: 1;
      position: relative;

      @media (min-width: $bp-small-2) {
        max-width: calc(49% - 6px);
        width: calc(49% - 6px);
      }
    }
  }
}

.tiles--art,
.tiles--quarter {
  &:not(.tiles--buttons) ul:not(.pager) {
    li {
      flex-grow: 1;
      position: relative;
      width: 100%;

      @media (min-width: $bp-small-2) and (max-width: $bp-med-2 - 0.001) {
        max-width: calc(49% - 6px);
        width: calc(49% - 6px);
      }

      @media all and (min-width: $bp-med-2) {
        max-width: 25%;
        width: 25%;
      }
    }

    .lightbox {
      align-items: center;
      display: flex;
      height: 300px;
      justify-content: center;

      @media (min-width: $bp-small-2) {
        height: 300px;
      }

      .tms-media-image {
        flex-grow: 1;
      }
    }

    img {
      width: auto;
    }
  }
}

.tiles--fifth {
  &:not(.tiles--buttons) ul:not(.pager) {
    li {
      flex-grow: 1;
      position: relative;

      @media (min-width: $bp-small-2) and (max-width: $bp-med-2 - 0.001) {
        max-width: calc(49% - 6px);
        width: calc(49% - 6px);
      }

      @media all and (min-width: $bp-med-2) {
        max-width: 20%;
        width: 20%;
      }
    }

    .lightbox {
      align-items: center;
      display: flex !important;
      height: 300px;
      justify-content: center;

      .tms-media-image {
        flex-grow: 1;
      }
    }

    img {
      width: auto;
    }
  }
}

.tiles--sixth {
  &:not(.tiles--buttons) ul:not(.pager) {
    li {
      flex-grow: 1;
      position: relative;

      @media (min-width: $bp-small-2) and (max-width: $bp-med-2 - 0.001) {
        max-width: calc(49% - 6px);
        width: calc(49% - 6px);
      }

      @media (min-width: $bp-med-2) and (max-width: $bp-xl - 0.001) {
        max-width: 25%;
        width: 25%;
      }

      @media (min-width: $bp-xl) {
        max-width: 16.5%;
        width: 16.5%;
      }
    }

    .lightbox {
      align-items: flex-end;
      display: flex;
      height: 180px; //temporary fix for mobile
      max-height: 180px;
      text-align: center;

      .tms-media-image {
        flex-grow: 1;
      }

      + .container {
        padding: 20px 0 0;
      }
    }

    img {
      bottom: 0;
      left: 0;
      margin: 0 auto;
      max-height: 180px;
      position: absolute;
      right: 0;
      width: auto !important;
    }
  }
}

.tiles--buttons {
  ul {
    padding-left: 0;
    gap: 10px;
  }

  li {
    flex-grow: 1;
  }

  .btn {
    border: 2px solid transparent;
    display: block;
    height: 100%;
    line-height: 1.5;
    padding: 20px;
    text-transform: unset;

    &::after {
      display: none;
    }

    &:focus,
    &:hover {
      background-color: $white !important;
      border-color: $black !important;
      color: $black !important;
      text-decoration: underline;
    }
  }
}
