.view-collections {
  .view-content {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
  }

  .collection-link {
    line-height: 0;
    position: relative;
    width: 50%;

    @media (min-width: $bp-med) {
      width: 20%;
    }
    @media (min-width: $bp-med) {
      > h3 {
        background: transparentize($black, 0.3);
        color: $white;
        display: none;
        font-size: 1.2em;
        height: 100%;
        margin: 0;
        min-width: 100%;
        padding: 50% 5%;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 0;
      }
    }
    &:hover,
    &:focus {
      > h3 {
        display: block;
      }
    }
  }

  @media (max-width: $bp-small-3) {
    > h3 {
      color: $white;
      display: block;
      font-size: 1.2em;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  img {
    width: 100%;
  }
}

.page-collections {
  .page__title {
    display: none;
  }

  &.page-collections-in-the-galleries,
  &.page-collections-explore {
    .page__title {
      display: block;
    }
  }
}
.page-collections-objects {
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .page__title {
    display: none;
  }
  .row:nth-child(2) {
    margin: 2em 0;
  }
  #objects_slider.flexslider .slides {
    li {
      text-align: center;
    }
    img {
      margin: 0 auto;
      max-height: 300px;
      max-width: 300px;
      width: auto;
    }
  }

  #objects_carousel.flexslider .slides {
    li {
      text-align: center;
    }
    img {
      border: 1px solid $gray-drk2;
      max-width: 40px;
      max-height: 40px;
      width: auto;
    }
    .flex-active-slide img {
      border: 1px solid $primary-brand;
    }
  }
}

.curator {
  display: inline-block;

  .curator__picture {
    @include span-columns(12);

    @media all and (min-width: $bp-med) {
      @include span-columns(3);
    }
  }

  .curator__bio {
    @include span-columns(12);

    @media all and (min-width: $bp-med) {
      @include span-columns(9);
    }
  }
}
