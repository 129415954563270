table {
  width: 100%;

  a {
    border-bottom: 1px solid $primary-brand;
    word-wrap: break-word;

    &:hover,
    &:focus {
      border-bottom: 1px solid $gray-drk1;
    }
  }
}

th {
  text-align: left;
}
