.thumbnail {
  img {
    @include transition(padding 0.5s ease-out, border-color 0.5s ease-out);
    border: 4px solid $black;
    padding: 1em;
  }

  &:hover img,
  &:focus img {
    border: 4px solid $primary-brand;
  }
}

.thumbnail--circle {
  img {
    max-width: 75px;
    padding: 7px;
    border: 4px solid transparent;
    @include border-top-radius(50%);
    @include border-right-radius(50%);
    @include border-bottom-radius(50%);
    @include border-left-radius(50%);

    @media all and (min-width: $bp-large) {
      max-width: 150px;
    }
  }

  &:hover img,
  &:focus img {
    border: 4px solid $primary-brand;
  }

  &.img-right {
    float: right;
    margin: 0 0 1em 1em;
    padding: 7px;
  }
}

a:hover,
a:focus {
  ~ .thumbnail img {
    border: 4px solid $primary-brand;
  }

  ~ .thumbnail--circle img {
    padding: 7px;
  }
}

.banner-image {
  .field-name-field-image-copyright-credit {
    background: rgba(0, 0, 0, 0.8);
    bottom: .5rem;
    color: $white;
    padding: .5rem;
    position: absolute;
    right: 0;
  }

  img {
    width: 100%;
  }
}

.image-caption-container {
  display: block;
  margin: 0 0 1em;
  max-width: 100%;

  @media all and (max-width: $bp-small-3 - 0.001em) {
    &.image-caption-container-left,
    &.image-caption-container-right {
      float: none;
      margin: 0 0 1em;
    }
  }

  @media all and (min-width: $bp-small-3) {
    &.image-caption-container-left {
      float: left;
      margin: 0 1em 1em 0;
    }

    &.image-caption-container-right {
      float: right;
      margin: 0 0 1em 1em;
    }
  }
}

.image-caption {
  color: $gray-drk1;
  font-size: 0.875em;
  border-left: 2px solid $primary-brand;
  margin: 0.5em 0;
  padding: 0 0.5em;
  width: 100%;
}

/* Ensure only one banner is displayed per page */
.region-highlighted .block-bean + .block-bean {
  display: none;
}
