// Search/Explore
.collections-search {
  background-color: $orange-lgt1;
  padding: 20px;

  @media all and (min-width: 768px) {
    padding: 2rem 6rem;
  }

  @media all and (min-width: 1024px) {
    padding: 2rem calc((100% - 1100px) / 2);
  }

  form {
    position: relative;
  }

  input.form-text {
    background: transparent;
    border: 1px solid $gray-drk1;
    font-size: $font-size-small-2;
    padding: 1rem;
    width: 100%;
  }

  .btn {
    background-color: $orange-lgt1;
    height: 48px;
    margin: 2px;
    position: absolute;
    right: 0;
    top: 0;
    width: 54px;

    &::after {
      content: "";
    }

    svg {
      margin-top: 13px;
      max-width: 32px;
    }
  }

  .label {
    color: $gray-drk1;
    font-size: $font-size-small;
    margin-bottom: 1em;
    margin-top: 3.5em;
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media all and (min-width: 600px) {
      flex-direction: row;
    }
  }

  li {
    margin-bottom: 1rem;

    @media all and (min-width: 600px) {
      margin-right: 3rem;
    }
  }

  a {
    border-bottom: 1px solid $primary-brand;
    font-size: $font-size-med-2;
    padding-bottom: 1px;
  }
}

// Featured Sets
.infinity {
  margin: 0 auto;
  width: 100%;

  @media all and (min-width: 769px) {
    padding: 3em 2.222em;
  }

  @media all and (min-width: 1440px) {
    padding: 3em 0;
    width: 1200px;
  }

  li {
    padding: 20px;

    @media all and (min-width: 768px) {
      align-content: center;
      display: flex;
      flex-wrap: wrap;
      min-height: 500px;
      max-width: 400px;
      width: 50%;
    }

    @media all and (min-width: 1024px) {
      width: 33.333%;
    }

    img {
        border-bottom: 4px solid transparent;
    }

    .mask:hover ~ div ,
    .mask:focus ~ div  {
      img {
        border-bottom-color: $primary-brand;
        transition: border-bottom-color .35s ease-in-out;
      }

      > title {
        border-bottom-color: $gray-drk1;
      }
    }

    .views-field-field-thumbnail {
      width: 100%;
    }

    .lead {
      font-family: $font-secondary;
      font-size: $font-size-smallest;
      font-weight: 700;
      text-transform: uppercase;
    }

    .title {
      border-bottom: 1px solid $primary-brand;
      line-height: 1.4;

      + p {
        margin-top: $space-extra;
      }
    }

    p {
      font-size: $font-size-small-2;
    }
  }
}

// Collection Stories
.view-featured-stories.highlight {
  background-color: $orange-lgt1;
  display: flex;
  flex-direction: column;

  &.archive {
    background-color: $white;
    flex-wrap: wrap;

    .view-content .item-list {
      width: 100%;
    }
  }

  @media all and (min-width: 769px) {
    flex-direction: row;
    padding: 3em 2.222em;
  }

  @media all and (min-width: 1440px) {
    padding: 3em calc((100vw - 1376px) / 2);
  }

  .view-header {
    @media all and (min-width: 769px) {
      width: 33.3%;
    }

    .h1 {
      margin-top: 0;
      @media all and (min-width: 769px) {
        max-width: 200px;
      }
    }
  }

  .view-content,
  .view-empty {
    align-content: center;
    display: flex;
    flex-direction: column;

    @media all and (min-width: 769px) {
      width: 66.7%;
    }

    > div {
      border-top: 1px solid $black;
      padding: 1em 0;
      position: relative;
    }
  }

  .h2 {
    margin-bottom: $space-small;
    margin-top: 0;
  }

  .field-name-field-teaser {
    font-size: $font-size-small-2;
    margin-bottom: 1em;
  }
}
