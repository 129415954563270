ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.field {
  ol,
  ul {
    margin: 1em 0;
    padding-left: 2em;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  li {
    margin-bottom: 0.5em;
  }
}

.ul--horizontal {
  li {
    float: left;
  }
}

/* Definition Lists */
dl {
  overflow: hidden;
  margin: 0 0 $space;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

dl.museum-hours {
  display: grid;
  grid-template-columns: 40% 1fr;

  dt {
    font-weight: 300;
    grid-column: 1;
    margin-bottom: .5rem;
  }

  dd {
    grid-column: 2;
  }
}

dl.contact-info {
  dt {
    @include span-columns(5);
    clear: both;
    margin-bottom: 0.5em;

    @media all and (min-width: $bp-med-2) {
      @include span-columns(3);
    }
  }

  dd {
    @include span-columns(6);

    @media all and (min-width: $bp-med-2) {
      @include span-columns(8);
    }
  }
}

ul.transportation {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  list-style: none;
  margin: 0.5em 0;
  padding: 0;

  li {
    width: 2em;
    text-align: center;
    margin: 0 1em 0 0;
    align-self: flex-end;
  }

  .icon-train,
  .icon-bus {
    font-size: 1.5em;
  }

  .icon-cab {
    font-size: 1.3em;
  }

  span {
    display: block;
  }
}

ul.social-sharing {
  font-size: 2em;
  // position: absolute;
  // top: 0.6em;

  li {
    display: inline;

    &:first-child [class^="icon-"]:before,
    &:first-child [class*=" icon-"]:before {
      margin-left: 0;
    }
  }

  a {
    border-bottom: 0;

    &:hover,
    &:focus {
      border-bottom: 0;

      i:before {
        color: $primary-brand;
      }
    }

    span {
      display: inline-block;
      text-indent: -9999px;
    }
  }
}

ul.press-list {
  li {
    margin-bottom: 1.5em;
    position: relative;
  }
}
