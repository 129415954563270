.node-type-docent-page,
.node-type-docent-resource {
  .page__title {
    display: none;
    visibility: hidden;
  }

  .file-icon {
    display: none !important;
  }

  .field-type-file .field-item {
    border-top: 1px #ccc dashed;
    padding: 10px 0;
  }
}

.docent-nav ul {
  align-items: center;
  border-bottom: 3px solid $primary-brand;
  margin-bottom: 1em !important;
  padding-bottom: 1em;

  @media all and (min-width: 768px) {
    display: flex;
  }
}
.docent-nav li.first {
  flex-grow: 1;
  font-size: 2em;
}
.docent-nav a {
  font-weight: 500;
  margin-right: 1em;
  text-transform: uppercase;
}
