/* Leaflet Maps */
#map {
  height: 66vh;
  z-index: 1;

  // @media (min-width: 46.8em) {
  //   height: 66vh;
  // }
}

.leaflet-container a.leaflet-popup-close-button {
  color: $black;
  height: 44px;
  width: 44px;
  z-index: 100;

  &:hover, &:focus {
    color: $primary-brand;
    text-decoration: underline;
  }
}

.leaflet-popup-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  min-height: 200px;

  img {
    width: auto;
  }

  @media all and (max-width: 767px) {
    img{
      max-height: 150px;
      max-width: 150px;
    }
  }

  @media all and (min-width: 768px) {
    margin: 20px;
    max-height: 300px;
    max-width: 300px;
    min-height: 300px;

    img {
      max-height: 200px;
      max-width: 200px;
    }
  }
}
.marker-cluster div {
  align-items: center;
  border: 2px solid $black;
  border-radius: 50%;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  margin-left: 2px;
  margin-top: 2px;
  width: 40px;
}

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: transparent;
  border-radius: 50%;
  height: 44px !important;
  width: 44px !important;

  div {
    background-color: rgba(255, 96, 22, 0.9);
  }

  span {
    font-weight: 700;
  }
}

.leaflet-overlay-pane {
  z-index: 700;
}

.leaflet-popup-pane {
  z-index: 800;
}
