/* Content */
.layout-main {
  padding-top: 131px;

  @media all and (min-width: $bp-med) {
    padding-top: 93px;
  }
}

.page__title,
.collection__title {
  padding: 20px 20px 0;
}

/* Visually Hide Page Titles */
.front,
.node-type-category,
.node-type-event {
  .page__title {
    left: -9999px;
    position: absolute;
    top: -9999px;
  }
}

p {
  margin-top: 0;
}

img {
  height: auto !important; //needed to override inline height
  max-width: 100%;
}

.img-left {
  margin-bottom: 1em;
  @media (min-width: $bp-small + 1) {
    float: left;
    margin: 0 1em 1em 0;
  }
}

.img-right {
  margin-bottom: 1em;
  @media (min-width: $bp-small + 1) {
    float: right;
    margin: 0 0 1em 1em;
  }
}

.hide-mobile {
  @media (max-width: $bp-small) {
    display: none;
  }
}

.hide-tablet {
  @media (max-width: $bp-med) {
    display: none;
  }
}

.hide-desktop {
  @media (min-width: $bp-med + 1) {
    display: none;
  }
}

.hide-all {
  display: none;
}

figure {
  @media (max-width: $bp-small) {
    float: none !important;
  }
}

/* Forms */
input {
  border: 1px solid $gray-drk3;
  padding: 0.25em 0.5em;

  &[type="submit"] {
    background: $gray-drk3;
    color: $white;
  }
}

/* Menu Block */
.menu-block-1 {
  ul {
    padding: 0;
    margin: 0;
    ul {
      padding-left: 1em;
    }
  }
  .menu__item {
    list-style-image: none;
    list-style-type: none;
    padding: 0.5em 0;
  }
}

/* Homepage */
//.front .column {
//  @media all and (max-width: $bp-small){
//    text-align: center;
//  }
//}

// Hide panel contextual links on homepage
.front .content > .contextual-links-region > .contextual-links-wrapper {
  display: none !important;
}

/* Homepage Carousel Banner */
.front .pane-banner {
  position: absolute;
  right: -10px;
  z-index: 10;
  top: -11px;
}

.banner-container {
  width: 300px;
  height: 300px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;

  &:after {
    content: "";
    display: block;
    width: 10px;
    height: 5px;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  &:before {
    content: "";
    display: block;
    height: 10px;
    width: 5px;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .banner {
    width: 400px;
    padding: 5%;
    background-color: #cb9f2d;
    color: #fff;
    line-height: 1.1;
    text-align: center;
    -webkit-transform: rotate(45deg) translate(6%, 25%);
    transform: rotate(45deg) translate(6%, 25%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
    font-size: 1.4em;
    font-variant: small-caps;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  }

  .banner-close {
    background: #333;
    padding: 2px 8px;
    border-radius: 100%;
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0.7;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
}

.mobile-buttons {
  margin-bottom: 1em;
  text-align: center;
}

.btn__home {
  background: $gray-drk3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
  margin-bottom: 0.25em;
  padding: 1.5em 0.4em;
  width: 28%;
}

/* Objects */
.tms-media-image {
  text-align: center;
}

.section-collections,
.page-collections {
  .collection__title {
    @media (min-width: $bp-med) {
      float: left;
    }
  }

  .pane-collection-search {
    //padding: 20px;

    label {
      display: none;
    }

    //div {
    //  display: inline;
    //}

    .form-item-search-input {
      display: inline;
      max-width: 70%;
    }

    a {
      display: block;
    }
  }

  .pane__browse-collection {
    @media (max-width: $bp-small) {
      clear: both;
      margin: 0 1em;
    }
    @media (min-width: $bp-small+ 1) {
      clear: right;
      float: right;
    }
  }
  .col-md-12 .col-md-12 {
    margin: 1em 0;
    clear: both;
  }
  .view-header,
  .view-content,
  .attachment {
    clear: both;
  }
}

.page-search-collections {
  .page__title {
    float: none;
  }
}

.page-node-373 {
  .page__title {
    display: none;
  }
}

.browse-collections {
  text-align: center;
}

.view-collection-online-galleries {
  .views-row {
    clear: both;
  }
}

/* MAKER */
.view-collections-browse-by-artist .view-content {
  display: flex;
  width: 80%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 1.25em;

  span {
    flex-grow: 1;
  }
}

.page-collections-maker {
  .maker-viewall {
    float: right;
  }

  .flex-direction-nav {
    z-index: 1000;

    .flex-prev {
      display: block;
      opacity: 1;
      left: 10px;
    }

    .flex-next {
      display: block;
      opacity: 1;
      right: 10px;
    }

    a {
      top: 100px;
    }
  }

  .flexslider {
    .slides {
      > li {
        margin: 0;

        .views-field-invno {
          visibility: hidden;
          text-align: center;
        }

        &:hover,
        &:focus {
          .views-field-invno {
            visibility: visible;
          }
        }
      }

      img {
        width: auto;
      }
    }
  }
}

/* Videos */
.oembed {
  float: none;
  border: 0;
  background: 0;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 0;

  .oembed-title {
    display: none;
  }
}

.kWidgetIframeContainer {
  min-width: 100%;
}

/* Tabbed Interface */
#quicktabs-object_data {
  border: 4px solid $gray;
  border-top: 0;
}

.quicktabs_main.quicktabs-style-nostyle {
  clear: both;
}

ul.quicktabs-tabs.quicktabs-style-nostyle {
  background: $gray;
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 0;
  text-transform: uppercase;

  li {
    padding: 0;
  }
}

ul.quicktabs-tabs.quicktabs-style-nostyle li a {
  color: $gray-drk2;
  padding: 5px 10px 10px;
}

ul.quicktabs-tabs.quicktabs-style-nostyle li.active a,
ul.quicktabs-tabs.quicktabs-style-nostyle li.active a:hover,
ul.quicktabs-tabs.quicktabs-style-nostyle li.active a:focus {
  background: $white;
  border-radius: 10px 10px 0 0;
}

#quicktabs-container-object_data {
  background: $white;
  padding: 0 15px 15px;
}

/* Campus Art Button */
.btn__campus-art {
  p {
    background: $gray-lgt2;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
    margin: 0 auto 1em;
    max-width: 250px;
    padding: 1em;
  }

  img {
    margin: 0 auto;
  }
}

/* Customizations for Video page layout */
.museum-videos {
  max-width: 33%;
  vertical-align: top;

  .kaltura-video .oembed.oembed-video {
    width: 300px;
    float: none;
    display: inline-block;
    margin: 0;
    background: none;
    border: none;
    padding: 0;
  }
}

.kaltura-video,
.video__player,
.bean-feature {
  .oembed.oembed-video {
    .oembed-title {
      display: none;
    }
  }

  .oembed-content {
    display: block;
    margin: 0 auto;
    max-width: 728px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    &::before {
      display: block;
      content: "";
      padding-top: percentage(9 / 16);
    }

    iframe,
    embed,
    object,
    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}

.video__player {
  margin-bottom: 1em;
  @media all and (min-width: $bp-med-2) {
    @include span-columns(4);
  }
}

.video__info {
  @media all and (min-width: $bp-med-2) {
    @include span-columns(8);
  }

  .read-more {
    display: none;
  }
}

.kaltura-video {
  .tiles--third & {
    align-items: center;
    background: $black;
    display: flex;

    @media all and (min-width: $bp-small-2) and (max-width: $bp-large-2 - 0.001) {
      min-height: calc((48vw - 36px) * .625);
    }

    @media all and (min-width: $bp-large-2) {
      min-height: calc((33.333vw - 28px) * .625);
    }
  }
}

.field-name-field-related .field-item {
  position: relative;
}

.japanese-workshop-art {
  .pane-language {
    text-align: right;
  }

  ul.language-switcher-locale-url {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;

      &.fr {
        display: none;
      }
    }
  }
}

.copytext {
  cursor: pointer;
  font-weight: bold;
}

/* The .mask class overlays underlying elements, so they are not selectable,
  such as image and also links for accessibility concerns. */
.mask {
  left: 0;
  height: 100%;
  position: absolute;
  text-indent: -9999px;
  top: 0;
  width: 100%;
  z-index: 10;
}

/* Temporary Overrides */
div.qtip {
  display: none !important;
}

.block-acquia-purge {
  form {
    margin-bottom: 0;
  }

  .form-submit {
    border: 20px solid $white;
    width: 100%;
  }
}

.feedbackCollect-container,
.feedbackCollect-form-container {
  z-index: 11 !important;

  .vertical-tabs {
    display: none;
  }
}

.view-footer {
  margin: 1em 0 0;
}

/* Sign Up / Log In / Request Password */
.not-logged-in.page-user .region-content {
  padding: 0 20px;
}

/* URL Param Switchers */
.pane-urlparam-switcher {
  float: right;
  margin-left: 1em;

  label {
    display: inline-block;
  }
}

/* Special Hours Notice */
.special-hours {
  padding: 1rem;

  @media all and (min-width: $bp-med-2) {
    position: fixed;
    width: 100%;
    z-index: 11;
  }

  &:empty {
    display: none;
  }

  &--light {
    background: $orange-lgt1;
    color: $black;

    a {
      border-bottom: 2px solid $primary-brand;
      color: $black;
      word-wrap: break-word;

      &:hover,
      &:focus {
        border-bottom: 1px solid $gray-drk1;
        color: $gray-drk1;
      }
    }
  }

  &--dark {
    background: $black;
    color: $gray-lgt1;

    a {
      border-bottom: 1px solid $white;
      color: $white;
      word-wrap: break-word;

      &:hover,
      &:focus {
        border-bottom-color: $primary-brand;
        color: $primary-brand;
      }
    }
  }

  /* Content with Alert */
  + .region-highlighted,
  + .layout-content {
    @media all and (min-width: $bp-small-3) {
      padding-top: 113px;
    }

    @media all and (min-width: $bp-med) {
      padding-top: 86px;
    }

    @media all and (min-width: $bp-xxxl) {
      padding-top: 59px;
    }
  }

  &:empty {
    + .region-highlighted,
    + .layout-content {
      padding-top: 0 !important;
    }
  }
}

.node-type-blog-post,
.node-type-object-package,
.pane-tms-collections-field-body,
.node-type-resource {
  .page__title,
  .field-name-body,
  .paragraphs-item-single-column-textarea,
  .paragraphs-item-media-and-text,
  .paragraphs-item-images .slider,
  .field-name-field-content-areas
    > .field-items
    > .field-item
    > .paragraphs-item-image-and-caption,
  .field-name-field-content-areas
    > .field-items
    > .field-item
    > .field-item
    > .paragraphs-item-image-and-caption,
  .mirador-embed-wrapper {
    margin: 0 auto 1rem;

    @media all and (min-width: 980px) {
      margin: 0 calc((100vw - 980px) / 2) 1rem;
    }
  }


  @media all and (min-width: 768px) {
    .mirador-embed-wrapper--wide {
      margin: 0 calc((100vw - 1200px) / 2) 1rem;
    }
  }

  .media-text {
    display: flex;
    flex-direction: column;

    .media-text__media {
      position: relative;
    }

    @media all and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;

      .media-text__text {
        width: 58.5%;
      }

      .media-text__media {
        width: 38.5%;
      }
    }

    .field-name-field-caption {
      @extend .image-caption;
    }
  }

  .grid .field-name-field-slide-image-text {
    > .field-items {
      align-items: baseline;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;

      @media all and (min-width: $bp-med) {
        flex-direction: row;
      }

      > .field-item {
        padding: 0.5rem;

        @media all and (min-width: $bp-small-3) {
          width: 50%;
        }

        @media all and (min-width: $bp-large-2) {
          width: 25%;
        }
      }
    }

    .field-name-field-caption {
      @extend .image-caption;
    }
  }

  .slider {
    .slick-next,
    .slick-prev {
      height: auto;
      padding: 0.5rem 1rem;
      width: auto;
    }

    .slick-slide img {
      margin: 0 auto;
      max-height: 600px;
      max-width: 600px;
      width: auto;
    }

    .img-caption .field-name-field-caption {
      color: $black;
      font-size: 1rem;
      border-left: 0;
      margin: 0 auto;
      padding: 0.5rem 0.5rem 0;
      max-width: calc(100% - 100px);
    }
  }

  .img-caption {
    position: relative;
    text-align: center;

    .field-name-field-caption {
      @extend .image-caption;
      text-align: left;
    }
  }

  table {
    width: 100% !important;
  }
}

.node-type-page {
  @media all and (min-width: 1024px) {
    .container--max .grid .field-name-field-slide-image-text > .field-items {
      margin-left: -40%;
      width: 180%;
    }
  }

  .grid .field-name-field-slide-image-text {
    > .field-items {
      align-items: baseline;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;

      @media all and (min-width: $bp-med) {
        flex-direction: row;
      }

      > .field-item {
        padding: 0.5rem;

        @media all and (min-width: $bp-small-3) {
          width: 50%;
        }

        @media all and (min-width: $bp-large-2) {
          width: 25%;
        }
      }
    }

    .field-name-field-caption {
      @extend .image-caption;
    }
  }

  .slider {
    .slick-next,
    .slick-prev {
      height: auto;
      padding: 0.5rem 1rem;
      width: auto;
    }

    .slick-slide img {
      margin: 0 auto;
      max-height: 600px;
      max-width: 600px;
      width: auto;
    }

    .img-caption .field-name-field-caption {
      color: $black;
      font-size: 1rem;
      border-left: 0;
      margin: 0 auto;
      padding: 0.5rem 0.5rem 0;
      max-width: calc(100% - 100px);
    }
  }

  .img-caption {
    position: relative;
    text-align: center;

    .field-name-field-caption {
      @extend .image-caption;
      text-align: left;
    }
  }
}

.visually-hidden,
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.course {
  &__resources {
    li {
      margin-bottom: 0.5rem;
    }
  }
}

// New Museum

.new-museum {
  .page__title {
    margin: 0 auto;
    max-width: 728px;
  }

  .tiles--quarter {
    li {
      background: #eee;
      margin: 0.5rem;

      @media (min-width: $bp-small-2) and (max-width: $bp-med-2 - 0.001) {
        max-width: calc(48% - 1rem) !important;
      }

      @media all and (min-width: $bp-med-2) {
        max-width: calc(25% - 1rem) !important;
      }
    }

    h2 {
      border-bottom: 2px solid;
      display: inline-block;
      padding-bottom: .25rem;

      a.mask:hover +,
      a.mask:focus + {
        border-bottom-color: $primary-brand;
        color: $primary-brand;
      }
    }
  }
}

.container--max .page__title {
  padding: 20px 0 0;
}

// Align title when .puam-onecol-max layout is used.
.puam-onecol-max .page__title {
  margin: 0 auto 1rem;

  @media all and (min-width: 980px) {
    margin: 0 calc((100vw - 980px) / 2) 1rem;
  }
}

// Single Question Website Survey
.region-highlighted .block-webform {
  background-color: $orange-lgt1;
  margin-bottom: 0;

  @media screen and (min-width: 40em) {
    form {
      padding: 20px;

      > div {
        align-items: flex-end;
        display: flex;
        justify-content: center;
      }
    }

    .webform-confirmation {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }
  }

  .form-item {
    margin: 0 .5rem 0 0;
  }

  .form-select {
    max-width: 80vw;
    padding: 7.5px 2px;
  }

  .form-text {
    max-width: 80vw;
  }

  .links {
    display: none;
  }
}

h2, h3 {
  a[id] {
    scroll-margin-top: 150px;
  }
}

.node-type-event {
  .field-name-field-event-location-ref,
  .field-name-field-event-date,
  .field-name-field-registration-link {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-block: .5rem;

    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: 27px;
      width: 20px;
    }
  }

  .field-name-field-event-location-ref::before {
    background-image: url('/sites/all/themes/puam_modern/images/location-dot-sharp-solid.svg');
  }

  .field-name-field-event-date::before {
    background-image: url('/sites/all/themes/puam_modern/images/clock-sharp-solid.svg');
  }

  .field-name-field-registration-link {
    &::before {
    background-image: url('/sites/all/themes/puam_modern/images/circle-info-sharp-solid.svg');
    }

    p:first-child:last-child {
      margin-bottom: 0;
    }
  }
}
