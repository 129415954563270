/*------------------------------------*\
$NAVIGATION
\*------------------------------------*/

.breadcrumbs {
  display: inline-block;
  float: right;

  li {
    display: inline-block;
    margin-left: $pad-half;
    color: $black;

    &:first-child {
      margin-left: 0;
    }
  }
}

.show-search {
  float: right;
  color: $white;

  .icon-search {
    float: right;
    color: $white;
  }
}

.nav > .menu {
  float: right;
}

.nav {
  display: none;
  width: auto;
  position: relative;
  font-family: $font-secondary;
  letter-spacing: $letter-spacing-link;

  @media all and (min-width: $bp-menu) {
    display: block;
  }

  li {
    position: relative;
    text-transform: uppercase;

    &:last-child {
      a {
        padding-right: 0;
      }
    }
  }

  a {
    color: $white;
    letter-spacing: $letter-spacing-link;
    font-size: 18px;
    @include font-black;
    float: right;
    display: block;
    padding: $pad 0.4em;
    text-decoration: none;

    @media all and (min-width: 1160px) {
      padding: $pad $pad-three-quarter;
    }

    &.active,
    &.active-trail,
    &:hover,
    &:active,
    &:focus {
      color: $primary-brand;
    }

    &:hover,
    &:focus {
      color: $primary-brand;

      + .menu {
        display: block;
      }
    }

    + .menu {
      &:hover,
      &:focus {
        color: $primary-brand;
        display: block;
      }
    }
  }

  &.active {
    max-height: 40em;
  }

  @media all and (min-width: $bp-med) {
    li {
      float: left;
    }
  }
}

li {
  .menu {
    background-color: $black;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 0;
    display: none;
    float: left;
    left: 0;
    list-style: none;
    min-width: 220px;
    margin: -15px 0 0 -25px;
    padding: 0;
    position: relative;
    text-align: left;
    top: 0;
    z-index: 1010;

    @media all and (min-width: $bp-menu) {
      position: absolute;
      top: 100%;
      left: $space;
    }

    &.show-menu {
      display: block;
    }

    li {
      display: inline-block;
      float: none;
      position: relative;
      width: 100%;
      border: 0;

      &.first > a {
        padding: 15px 20px 10px;

        .dropdown-menu--right {
          margin: 0 0 0 -1px;
        }
      }

      a {
        float: left;
        text-transform: uppercase;
        font-size: $font-size-small-2;
        //padding: 2px 8px 2px 0;
        padding: 0 20px 10px;
        width: 100%;

        @media all and (min-width: $bp-menu) {
          font-size: $font-size-med;
        }
      }

      &:hover,
      &:focus {
        + .menu {
          @media all and (min-width: $bp-menu) {
            display: block;
          }
        }
      }

      + .menu {
        &:hover,
        &:focus {
          @media all and (min-width: $bp-menu) {
            display: block;
          }
        }
      }
    }
  }

  &:first-child {
    .menu {
      margin-top: 0;
    }
  }
}

li {
  li .menu {
    background-color: $black;
    display: none;
    margin: -15px 0 0 -1px;
    padding: 0;
    //white-space: nowrap;
    z-index: 1020;
    position: relative;
    left: 0;
    top: 0;

    @media all and (min-width: $bp-menu) {
      position: absolute;
      left: 100%;
      top: 0;
    }

    li {
      display: block;
      overflow: hidden;
      padding-right: 10px;
    }
  }

  // Flip flyout menu to opposite side for learn menu.
  &.menu-mlid-905 li .menu {
    @media all and (min-width: $bp-menu) {
      left: -99%;
      max-width: 220px;
    }
  }
}

//Pagination
.pagination {
  overflow: hidden;
  font-family: $font-secondary;
  letter-spacing: $letter-spacing-link;
  float: right;

  li {
    float: left;
    border: 0;
    padding: $pad-half;

    a {
      padding: $pad-half;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    button {
      background-color: transparent;
      border-radius: 50%;
      padding: 0 9px;
      text-indent: -9999px;
    }

    &.current {
      color: $white;
      background: $black;
    }
  }

  &.pagination--hero,
  &.pagination--micro-slider {
    li {
      border-right: none;
      border-right: 0;
      line-height: normal;
      padding: 0;
    }

    li:first-child {
      margin-left: 0;
    }

    li.slick-active {
      button {
        @include transition(all 0.25s ease-out);
        &:hover,
        &:focus {
          border-radius: 50%;
        }
      }
    }

    button {
      display: inline-block;
      border-radius: 50%;
      @include transition(all 0.25s ease-out);
      padding: 0;
      width: 20px;
      height: 20px;

      &:hover,
      &:focus {
        border-radius: 0;
        border: 2px solid $primary-brand;
      }
    }
  }

  &.pagination--hero {
    position: absolute;
    top: 43vw;
    right: auto;
    bottom: auto;
    left: 20px;

    @media all and (min-width: $bp-med-2) {
      top: auto;
      right: 30px;
      bottom: 30px;
      left: auto;
    }

    li {
      float: left;
      margin: 0 $pad-half;

      &:first-child {
        margin: 0 $pad-half 0 0;
      }

      @media all and (min-width: $bp-med-2) {
        float: none;
        margin: $pad-half 0;

        &:first-child {
          margin: $pad-half 0;
        }
      }
    }

    li.slick-active {
      button {
        background-color: $black;

        @media all and (min-width: $bp-med-2) {
          background-color: $white;
        }

        &:hover,
        &:focus {
          border: 2px solid $black;

          @media all and (min-width: $bp-med-2) {
            border: 2px solid $white;
          }
        }
      }
    }

    button {
      border: 2px solid $black;

      @media all and (min-width: $bp-med-2) {
        border: 2px solid $white;
      }
    }
  }

  &.pagination--micro-slider {
    li {
      margin-left: $pad;
      border-right: none;
      border-right: 0;
      padding: 0;
    }

    li.slick-active {
      button {
        background-color: $black;

        &:hover,
        &:focus {
          border: 2px solid $black;
        }
      }
    }

    button {
      border: 2px solid $black;
    }
  }

  &.pagination--dark {
    li.slick-active {
      button {
        background-color: $black;

        &:hover,
        &:focus {
          border: 2px solid $black;
        }
      }
    }

    button {
      border: 2px solid $black;
    }
  }
}

//Pager
.pager {
  font-family: $font-secondary;
  font-size: $font-size-med-3;
  letter-spacing: $letter-spacing-link;

  li {
    border: 0;
    padding: $pad-half;

    a {
      padding: $pad-half;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    &.pager-current {
      background: $black;
      color: $white;
      font-weight: 400;
    }
  }

  .pager-previous {
    a {
      color: transparent !important;

      &:after {
        font-family: "puamicons";
        font-style: normal;
        font-weight: normal;
        speak: none;

        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: 0.2em;
        text-align: center;
        /* opacity: .8; */

        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;

        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;

        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: 0.2em;

        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */

        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        content: "\e808";
        color: $black;
      }

      &:hover:after,
      &:focus:after {
        color: $primary-brand;
      }
    }
  }

  .pager-next {
    a {
      color: transparent !important;
      &:before {
        font-family: "puamicons";
        font-style: normal;
        font-weight: normal;
        speak: none;

        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: 0.2em;
        text-align: center;
        /* opacity: .8; */

        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;

        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;

        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: 0.2em;

        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */

        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        content: "\e807";
        color: $black;
      }

      &:hover:before,
      &:focus:before {
        color: $primary-brand;
      }
    }
  }
}

/* MEAN MENU OVERRIDES */
.navbar-administration {
  .mean-bar {
    margin-top: 39px;
  }

  &.navbar-horizontal.navbar-tray-open .mean-bar {
    margin-top: 79px;
  }
}

.mean-container {
  .mean-bar {
    position: absolute;
    background: transparent;
    top: 3.75%;
  }
}

.mean-container {
  .mean-bar {
    top: 25px;
    right: 0;
    padding: 0;
    width: 10vw;

    @media all and (min-width: $bp-med-2) {
      position: fixed;
    }

    a.meanmenu-reveal {
      padding: 13px 21px 11px 13px;
      -webkit-transition: none;
      transition: none;
    }
  }

  .mean-nav {
    margin-top: 57px;
    font-family: $font-secondary;
    @include font-black;
    position: absolute;
    left: -90vw;
    width: 100vw;

    ul {
      li {
        padding: 0;

        a {
          background-color: $black;
          border-top: 0;
          width: 90%;
          padding: 1em 5%;
          color: $white;
          opacity: 1;
          -webkit-transition: none;
          transition: none;

          &:hover,
          &:focus {
            background: #252525;
          }

          &.mean-expand {
            margin: 0;
            border-left: 0 !important;
            border-bottom: 0 !important;
            text-indent: 0;
            width: 38px;
            background: $black;
            color: $white;
            opacity: 1;

            &:hover,
            &:focus {
              background: #252525;
            }
          }
        }

        li {
          a {
            border-top: 0;
            width: 90%;
            padding: 1em 5%;
            text-indent: 15px;
            color: $white;
            opacity: 1;
            -webkit-transition: none;
            transition: none;
          }

          li {
            a {
              width: 90%;
              padding: 1em 5%;
              text-indent: 40px;
              color: $white;
              opacity: 1;
              -webkit-transition: none;
              transition: none;
            }
          }
        }
      }
    }
  }
}

/* Menu block */
.pane-menu-block-1 {
  .menu {
    > li {
      font-size: 1.2em;
      font-weight: 900;
      margin-bottom: 0.5em;
      text-transform: uppercase;
    }
  }

  a {
    &.active {
      color: $gray-drk1;
    }

    &:hover,
    &:focus {
      color: $primary-brand;
    }
  }
}
