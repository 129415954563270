.header {
  background: $black;
  color: $white;
  min-height: 131px;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 12;

  @media all and (min-width: $bp-med-2) {
    position: fixed;
    min-height: 93px;
  }

  a {
    color: $white;
    letter-spacing: $letter-spacing-link;

    &:hover,
    &:focus,
    &:active {
      color: $primary-brand;
    }
  }
}


.logo {
  background: url("../logo.png") no-repeat 0;
  background: url("../logo.svg") no-repeat 0, none;
  background-size: contain;
  display: inline-block;
  float: left;
  @include size(233px 50px);
  @include hide-text;
  margin: 22px 0 20px 20px;
  text-indent: -9999px;

  @media all and (min-width: 375px) {
    @include size(293px 50px);
  }
}

.nav-container {
  display: inline-block;
  float: right;
  margin: 0 20px 20px;
  width: 100%;

  @media all and (min-width: $bp-med-2) {
    margin: 16px 20px 0 0;
    width: auto;
  }

  .utilities {
    float: right;
    font-size: $font-size-small-2;
    overflow: hidden;

    .hours {
      -webkit-transition: margin-right 0.5s ease-out;
      -moz-transition: margin-right 0.5s ease-out;
      transition: margin-right 0.5s ease-out;
      margin-left: $space;
      display: inline-block;
    }

    .icon-search {
      color: $white;
      margin: 0;
      cursor: pointer;
      @include transition( color 0.5s ease-out);

      &:hover,
      &:focus {
        color: $primary-brand;
      }
    }

    .search {
      display: inline-block;
      overflow: hidden;
      vertical-align: middle;
      margin-bottom: 1px;

      .container-inline {
        @include transition( margin-right 0.5s ease-out);
        margin-right: -100vw;

        &.expand {
          margin-right: 0;
        }
      }

      &.expanded + .hours {
        -webkit-transition: margin-right 0.5s ease-out;
        -moz-transition: margin-right 0.5s ease-out;
        transition: margin-right 0.5s ease-out;
        margin-right: -100vw;

          @media all and (min-width: $bp-small-2) {
            margin-right: 0;
          }
      }

      .form-item {
        margin: 0;
      }
    }

    input[type="text"],
    input[type="search"] {
      display: inline-block;
      background: $black;
      border: 0;
      color: $white;
      padding: 0;
      min-width: 90px;
      margin: 0 20px 0 0;

      @include placeholder {
        color: $gray;
      }
    }

    label {
      display: inline-block;
      color: $white;
    }

    button,
    input[type="submit"] {
      display: inline-block;
      padding: 0;
      background: transparent;

      &:hover,
      &:focus {
        color: $primary-brand;
        background: transparent;
      }
    }
  }
}


//.nav-toggle {
//  float: right;
//  display: block;
//  padding: 0.9rem 1rem 0.7rem;
//  font-size: 1.3rem;
//  line-height: 1;
//  border-left: 1px solid #dbdbdb;
//
//  @media all and (min-width: $bp-med) {
//    display: none;
//  }
//
//  @media all and (max-width: 17em) {
//    padding-left: 0.2rem;
//    padding-right: 0.2rem;
//  }
//}

.block-search {
  display: none; // temporarily hide
  overflow: hidden;
  max-height: 0;

  @media all and (min-width: $bp-menu) {
    float: right;
    max-height: none;
    margin: 0.65em 0.5em 0 0;
  }
}
