/* Block */
.block:not(.block-system) {
  p:last-child {
    margin-bottom: 0;
  }
}

.headline {
  line-height: 1.2;
  margin: 0;
}

/* Hero Block */
.block-hero {
  margin-bottom: $space-half;

  .b-thumb {
    img {
      display: block;
    }
  }

  @media all and (min-width: $bp-large) {
    position: relative;

    .b-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $dim;
      color: $white;
      padding: $pad-and-half;
    }
  }
}

/* Block Thumbnail with Headline */
.block-thumb {
  display: table;
  width: 100%;
  border-collapse: collapse;

  .b-inner {
    display: table-row;
    vertical-align: top;
    overflow: hidden;
  }

  .b-thumb {
    @media all and (min-width: $bp-small-2) {
      display: table-cell;
      vertical-align: top;
      width: 30%;
      max-width: 10em;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .b-text {
    @media all and (min-width: $bp-small-2) {
      display: table-cell;
      width: 70%;
      padding: 0 $pad;
    }
  }
}

/* Block Headline Summary */
.block-headline-summary {
  a {
    display: block;
    padding: $pad-half;
  }
}

/* Block Inset */
.block-inset {
  position: relative;

  .b-thumb {
    position: relative;
    z-index: 0;

    img {
      display: block;
    }
  }
}

/* Hero Block */
.block-inset {
  margin-bottom: $space-half;
  position: relative;

  .headline {
    font-size: 1.1em;
  }

  .b-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $dim;
    color: $white;
    padding: $pad-half;
  }
}

/* Block Thumb with Summary */
.block-thumb-summary {
  .b-thumb {
    float: left;
    width: 50%;
  }

  .b-text {
    margin-left: 50%;
    padding: $pad-half;
  }
}
