.events {
  margin-bottom: 20px;

  i {
    margin-right: 10px;
  }

  a {
    display: inline-block;
    text-decoration: none;
    @include transition(all 0.25s ease-out);
    border-bottom: 0;

    &:hover,
    &:focus {
      border-bottom: 0;
    }
  }

  .meta {
    margin: 0;
  }

  .meta--date {
    margin: 10px 0 2px;
    font-weight: 600;
  }

  h3 {
    font-size: $font-size-aa;

    &:hover,
    &:focus {
      color: $primary-brand;
      cursor: pointer;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;

    @include span-columns(3);
    @include omega(4n);

    @media all and (max-width: $bp-small-3) {
      @include span-columns(6);
      @include omega(2n);
    }

    &:hover,
    &:focus {
      img {
        border: 4px solid $primary-brand;
      }

      h3 {
        color: $primary-brand;
      }
    }
  }

  img {
    padding: 10px;
    border: 4px solid $black;
    width: 100%;

    &:hover,
    &:focus {
      border: 4px solid $primary-brand;
    }
  }

  p,
  span {
    display: block;
    margin-bottom: $pad-quarter;
  }
}

.puam-layouts-meta {
  .pane-title {
    font-weight: 500;
  }

  .pane-content {
    margin-bottom: 1em;
  }
}

.field-name-field-event-format {
  .field-items {
    display: flex;
  }

  .field-item:nth-child(1)::before {
    content: 'Join ';
  }

  .field-item:nth-child(2)::before {
    content: 'or ';
    margin-left: .5ch;
  }
}

.event-links {
  a {
    border-bottom: 2px solid $primary-brand;
    display: inline-block;
    font-weight: 400;
    margin-bottom: $space;
    word-wrap: break-word;

    &:hover,
    &:focus {
      border-bottom: 1px solid $gray-drk1;
    }
  }
}
