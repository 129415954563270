.results-container,
.results-list {
  width: 90%;
  margin: auto;
}

// .result-list-info {
//   display: flex;
//   flex-direction: row-reverse;
//   -webkit-box-align: center;
//   align-items: center;
//   justify-content: left;
//   margin: 2em 0 3em;
// }

.result-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @supports(display:grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: repeat(4,minmax(min-content,max-content));
    column-gap: 2%;
    grid-column-gap: 2%;
    row-gap: 10px;
    grid-row-gap: 10px;
  }
}

.result-item {
  flex-direction: column;
  align-items: center;
  display: flex;

  a {
    border-bottom: 0 !important;

    &:hover, &:focus {
      color: $black;

      .object-title {
        text-decoration: underline;
        text-underline-offset: 5px;
        text-decoration-thickness: 2px;
      }
    }
  }

  .object-image {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .object-caption {
    font-size: $font-size-small-2;
    min-height: 100px;
    margin-bottom: 5px;
  }

  .object-title,
  .object-maker {
    margin-top: 1em;
  }

  // .object-date,
  // .object-maker {
  //   font-family: Roboto;
  //   font-weight: 400;
  //   font-size: 16px;
  // }
}
