.paragraphs-item-two-column-textareas {
  .content {
    @media (min-width: 769px) {
      display: flex;
    }

    > div {
      padding: 1%;

      @media (min-width: 769px) {
        padding: 1% 2%;
        width: 50%;
      }
    }
  }
}

.paragraphs-item-single-column-textarea {
  clear: both;
}

.paragraphs-item-class-list {
  margin-bottom: 2em;

  .field-name-field-class-name {
    font-size: $font-size-med-3;
    font-weight: 400;
    line-height: 1.2;
  }

  p {
    margin: .5em 0;
  }

  .field-name-field-class-links {
    .field-items {
      display: flex;
      flex-direction: column;

      @media all and (min-width: $bp-med) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .field-item {
      @media all and (min-width: $bp-med) {
        margin-right: 1em;
      }
    }

    a {
      @extend .btn;
      @extend .btn--action;
      margin-bottom: .5em;
    }
  }
}
