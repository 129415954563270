.views-exposed-form .views-exposed-widget {
  clear: both;
  display: inline-block;
  float: none;
  padding: 0;
}

/* Site Search */
.page-search-site .content .search-form {
  padding: 20px 20px 0;
}

.search-result {
  border-bottom: 4px solid $gray-lgt2;
  margin: 1.5em 0;
  padding-bottom: 1.5em;

  &:last-child {
    border-bottom: 0;
  }
}

.views-widget--primary {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100vw - 40px);

  @media all and (min-width: $bp-med-2){
    flex-wrap: nowrap;
  }

  .form-item-keys {
    flex-grow: 1;
    margin: 0 .5em;
    width: 50vw;

    @media all and (min-width: $bp-med-2){
      flex-grow: 0;
      margin-right: .5em;
    }

    .form-text {
      width: 100%;
      padding: 13px 5px;
    }
  }

  .form-submit,
  #edit-submit-collection-object-search,
  #edit-submit-collections-advanced-object-search,
  #edit-reset {
    flex-basis: calc(50% - 1em);
    margin: 1em .5em 0 !important;

    @media all and (min-width: $bp-med-2){
      flex-basis: 20%;
      margin-right: .5em;
      margin-top: 0 !important;
    }
  }
}

.views-widget--secondary {
  display: flex;
  flex-wrap: wrap;

  > * {
    flex-basis: calc(50% - 1em);
    margin: 1em .5em 0;

    @media all and (min-width: $bp-med-2) {
      flex-basis: calc(33.333% - 1em);
      margin-right: .5em;
      margin-top: 1em;
    }
  }

  input, select {
    width: 100%;
  }

  select {
    height: 39px;
  }

  .views-widget-filter-year_range {

    @media all and (min-width: $bp-med-2) {
      display: flex;
      flex-direction: row-reverse;
    }

    label {
      display: none;
    }

    .views-widget {
      flex-grow: 1;
      margin-right: 1em;
    }

    .form-item {
      margin-bottom: .5rem;
    }
  }


  .views-widget-filter-id {
    display: none;
  }
}

/* React Search */
#root {
  .sk-layout {
    &__top-bar {
      background: $black;
      top: 97px;
      z-index: 1;
    }

    &__body {
      margin: 0 auto;
      max-width: 100%;
      min-width: 0;
    }
  }

  .sk-toggle-option {
    border: 0;

    &.is-active {
      background: $black;
    }
  }
}

.navbar-horizontal,
.navbar-vertical {
  #root{
    .sk-layout {
      &__top-bar {
        top: 134px;
      }
    }
  }
}

.navbar-horizontal.navbar-tray-open {
  #root{
    .sk-layout {
      &__top-bar {
        top: 174px;
      }
    }
  }
}

.front.page-search-collections {
  .layout-main {
    padding-top: 174px;
  }
}
