/* Headings */
/*Further Reading: http:/csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css/ */
h1,
.h1,
.alpha {
  font-size: $font-size-largest;
  font-weight: 400;
  line-height: 1;
}

h2,
.h2,
.beta {
  font-size: $font-size-large;
  font-weight: 400;
  line-height: 1.4;
}

h3,
.h3,
.gamma {
  font-size: $font-size-med-3;
  font-weight: 400;
  line-height: 1.4;
}

h4,
.h4,
.delta {
  font-size: $font-size-small-2;
  font-weight: 500;
}

h5,
.h5,
.epsilon {
  font-size: $font-size-small-2;
  font-weight: 400;
}

h6,
.h6,
.zeta {
  font-size: $font-size-small;
  font-weight: 400;
}

/* Subheadings */
.subheading {
  font-family: $font-secondary;
  font-weight: normal;
}

/* Override UA Styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}
