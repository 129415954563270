.node-staff {
    display: flex;
    flex-direction: column;

    @media all and (min-width: $bp-large){
      flex-direction: row;
      .node-staff__image {
        width: 25%;
      }

      .node-staff__info {
        padding-left: 2rem;
        width: 73%;
      }
    }

  .node-staff__info .field-name-field-user-position {
      font-weight: 400;
      margin: 1.1111rem 0;
    }
}
