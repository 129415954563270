/* Overrides of fullcalendar and node/add for tour scheduling system */
#legend {
  padding: 20px;
}

#legend .legend-box {
  float: left;
  width: 25px;
  height: 15px;
  margin-right: 10px;
}

#legend .available, .calendar-calendar .available {
  background-color: #B1E267 !important;
}

#legend .unavailable, .calendar-calendar .unavailable {
  background-color: #ccc !important;
  margin-left: 15px;
}

#legend .group {
  background-color: $information !important;
}

#legend .course {
  background-color: $primary-brand !important;
  margin-left: 15px;
}

.calendar-calendar .unavailable {
  background-color: #ccc !important;
}

#legend p {float:left;}

.fc-header-title h2 {
  font-size: 1.7em;
  font-weight: bold;
}

.fc-other-month {
  background: #e4e4e4;
}

.fc-content a:link, .fc-content a:visited {
  min-height: 16px;
}

div.fc-event-inner.fc-event-skin {
  font-size: 12px;
}

.fc-event-default .fc-event-time {
  font-weight: normal;
  position: absolute;
  border: none;
  background: transparent;
  top: -3px;
  font-size: 14px;
}
.fc-view-agendaWeek .fc-event-vert {
  font-size: 0;
  overflow: hidden;
  width: 60px !important;
}

.fc-view-agendaWeek .fc-event-time,
.fc-view-agendaDay .fc-event-time {
  display: none;
}

.fc-view-month .fc-event-title {
  display: none;
}

.fc-view-agendaWeek .fc-event-title,
.fc-view-agendaDay .fc-event-title {
  font-size: 12px;
}

.page-learn-schedule-a-tour-or-visit-group-visits-calendar .fc-event-past {display: none;}

.colors-node-type-tour-time-slot,
.colors-node-type-tour-time-slot .fc-event-skin,
.colors-node-type-tour-time-slot .fc-event-time,
.colors-node-type-tour-time-slot a {
  color: #000000;
}

.colors-node-type-tour-time-slot.fc-event-past,
.colors-node-type-tour-time-slot.fc-event-past .fc-event-skin,
.colors-node-type-tour-time-slot.fc-event-past .fc-event-time,
.colors-node-type-tour-time-slot.fc-event-past a {
  background: #ccc !important;
  border-color: #ccc;
  pointer-events: none;
}

.colors-node-type-tour-time-slot.fc-event-unavailable,
.colors-node-type-tour-time-slot.fc-event-unavailable .fc-event-skin,
.colors-node-type-tour-time-slot.fc-event-unavailable .fc-event-time,
.colors-node-type-tour-time-slot.fc-event-unavailable a{
  background: #73b4d0 !important;
  border-color: #73b4d0;
  pointer-events: none;
}

.page-learn-schedule-a-tour-or-visit-group-visits-calendar #main .pagewidth {
  max-width: 930px;
}

/* Customization for Group Tour Request creation */
.page-node-add-tour-request {
  .page__title,
  .vertical-tabs {
    display: none;
  }
  fieldset {
    border: none;
    margin: 1em 0 0;
    padding: 0;
  }
  .fieldset-legend {
    font-size: $font-size-large;
  }
  .field-name-field-city,
  .field-name-field-state,
  .field-name-field-zip {
    float: left;
    margin-right: 1em;

    .form-item {
      margin-top: 0;
    }
  }
  input[type="submit"] {
    background: $primary-brand;
  }
}
.page-node-add-tour-request .group-title {
  font-size: 1.3em;
  font-weight: bold;
  text-transform: uppercase;
}

.page-node-add-tour-request #main label {
  font-weight: bold;
}

.page-node-add-tour-request #main label.option {
  font-weight: normal;
}

.page-node-add-tour-request #main #edit-field-tour-date,
.visit-info-left-column .description {
  display: none;
}

.page-node-add-tour-request #main .field-name-field-city,
.page-node-add-tour-request #main .field-name-field-state,
.page-node-add-tour-request #main .field-name-field-zip {
  float: left;
  margin-top: -15px;
}
.page-node-add-tour-request .form-textarea-wrapper.resizable.textarea-processed.resizable-textarea {
  max-width: 40em;
}

/* Customization for Faculty/Staff Tour Request creation */
.page-node-add-faculty-staff-tour-request {
  #main {
    .breadcrumb {
      display: none;
      visibility: hidden;
    }

    .node-form {
      padding: 20px;
    }

    fieldset {
      border: 0;
      padding: 0;
    }

    input[type=text] {
      padding: 0;
    }

    .fieldset-legend,
    label {
      font-weight: bold;
      margin-top: 1em;

      &.option {
        font-weight: normal;
      }
    }
  }

  .form-item-field-precept-group-information-und-form-status {
    display: none;
  }

  .group-visit-instructions .description {
    max-width: 930px;
  }

  .form-textarea-wrapper.resizable.textarea-processed.resizable-textarea {
    max-width: 40em;
  }

  #edit-field-precept-group-information {
    background: $gray-lgt1;
    margin: 1em 0;
    padding: 1em;
  }

  .date-no-float {
    clear:none;
    float: left;
    /*width: 30%;*/
  }

  .date-float .form-type-checkbox {display: none;}

  .form-item.form-type-date-popup.form-item-field-precept-group-information-und-form-field-tour-date-und-0-value2 {display: inline-block;}

  .container-inline-date {
    clear:none;
    width: 50%;
  }

  .end-date-wrapper.container-inline-date {
    margin-top: 23px;
  }

  .container-inline-date .date-padding {
    padding: 10px 0;
  }

  .form-item-field-precept-group-information-und-form-field-tour-date-und-0-value2-date {display: none;}

  .ui-timepicker-standard .ui-menu-item a {
    font-size: .8em;
  }
}


.container-inline-date .form-item .form-item {
  float: none;
}

.visit-info-left-column {
  margin-top: 1em;
  margin-bottom: 1em;
}

.visit-info-left-column,
.visit-info-middle-column,
.visit-info-right-column {
  float: left;
  //width: 30%
  /*width: 100%; *//*hotfix*/
  @include span-columns(12);

  @media (min-width: 768px) {
    @include span-columns(4);
  }
}

/* Customization of survey and results layouts */
.node-type-tour-survey #main,
.page-node-webform-results #main,
.page-node-submission #main {
  padding: 15px;
}
.node-type-tour-survey #main .pagewidth,
.page-node-webform-results #main .pagewidth,
.page-node-submission #main .pagewidth  {
  max-width: 930px;
}

.page-node-webform-results .webform-results-per-page,
.page-node-submission legend {
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: bold;
}
.page-node-submission .webform-submission-info {
  padding: 0;
}
.page-node-submission .user-picture,
.page-node-submission .webform-submission-info-text {
  display:none;
}
.node-type-tour-survey label,
.page-node-submission label {
  font-weight: bold;
}

.node-type-tour-survey label.option {
  font-weight: normal;
}

/* fix for IE start time displaying below data */
.form-item-field-precept-group-information-und-form-field-tour-date-und-0-value-date,
.form-item-field-precept-group-information-und-form-field-tour-date-und-0-value-time {
  display: inline;
  display: inline\9; /* IE 8 and below */
  *display: inline; /* IE 7 and below */
  _display: inline; /* IE 6 */
}

/* adjustment for tourcalendar_block */
select.tourcalendarblock {
  margin-left: 20px;
}

.field-name-field-tour-date .fieldset-legend {
  text-transform: capitalize;
}

.start-date-wrapper div.form-item + div.form-item:before {
  content: "Time";
  font-weight: bold;
}
.start-date-wrapper label,
.end-date-wrapper label,
.end-date-wrapper input.hasDatepicker {
  display: none !important;
}
.end-date-wrapper div.form-item + div.form-item:before {
  content: "to";
  font-weight: bold;
}
.ief-row-form .ief-form-row {
  border-bottom: none !important;
}
/* Fix font size of date selector popup */
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  font-size: 12px;
}

/* Hide Edit button on University Course Visits */
.ief-entity-operations input[value=Edit] {
  display: none;
}

.btn__tour {
  background: $gray-lgt2;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
  font-size: $font-size-large;
  margin: 0 auto 1em;
  padding: 1em;
  text-align: center;
}

.group-tour-admin {
  display: none;
}
