.page-search-collections .page__title {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// TODO: Should be updated in the react app.
.filter-row button {
  color: $black;
}
