a.resources-tile {
  align-items: center;
  background-size: cover;
  border-bottom: none;
  color: $white;
  display: flex;
  font-size: $font-size-large;
  justify-content: center;
  min-height: 10rem;

  @media all and (min-width: 768px) {
    min-height: 20rem;
  }

  > span {
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    padding: 1.5rem;
    width: 100%;
  }

  svg {
    margin-right: 1.5rem;
    width: 3rem;
  }

  &__video {
    background-image: url("/sites/all/themes/puam_modern/images/resources-video.jpg");
  }

  &__audio {
    background-image: url("/sites/all/themes/puam_modern/images/resources-audio.jpg");
  }

  &__writings {
    background-image: url("/sites/all/themes/puam_modern/images/resources-writings.jpg");
  }

  &__learning {
    background-image: url("/sites/all/themes/puam_modern/images/resources-learning.jpg");
  }

  &__looking {
    background-image: url("/sites/all/themes/puam_modern/images/resources-looking.jpg");
  }

  &__themes {
    background-image: url("/sites/all/themes/puam_modern/images/resources-themes.jpg");
  }
}

.resource-item {
  a {
    border-bottom: 0;
  }

  .resource-item--fallback {
    background: $orange-lgt1;
    > span {
      align-items: center;
      background: rgba(0, 0, 0, 0.7);
      color: $white;
      display: flex;
      padding: 1.5rem;
      width: 100%;
    }
  }

  h3 > span {
    align-items: center;
    background: transparent;
    display: flex;
    padding: 1.5rem;
    width: 100%;
  }

  svg {
    margin-right: 1.5rem;
    width: 3rem;
  }
}

.grid.grid--third {
  ul {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 2%;
  }

  .object {
    align-items: center;
    display: flex;
    flex-direction: column;

    a {
      border-bottom: 0;

      &:focus,
      &:hover {
        border-bottom: 0;
      }
    }
  }

  .object-image {
    align-items: center;
    display: flex;
    height: 300px;
    justify-content: center;
  }

  .object-caption {
    margin-bottom: 5px;
    margin-top: 1rem;
    min-height: 100px;
  }
}

.resource-audio__player {
  background: $black;
  margin: 20px 0;
  padding: 10px 0 0;
}

.resource__description {
  margin: 20px 0;
}
