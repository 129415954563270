.puam-layouts-header {
  @include span-columns(12);
}

.puam-twocol {
  .puam-layouts-column1,
  .puam-layouts-column2 {
    @include span-columns(12);

    @media all and (min-width: $bp-med-2) {
      @include span-columns(6);
    }
  }
}

.front {
  .puam-layouts-column1,
  .puam-layouts-column2 {
    @include span-columns(12);
    margin: 20px 0;

    @media all and (min-width: $bp-med-2) {
      width: 50%;
    }
  }
}

.puam-sidebar-left,
.puam-sidebar-right {
  .puam-layouts-content {
    @include span-columns(12);

    @media all and (min-width: $bp-med-2) {
      @include span-columns(8);
    }
  }

  .puam-layouts-sidebar {
    @include span-columns(12);

    @media all and (min-width: $bp-med-2) {
      @include span-columns(4);
    }
  }
}

.puam-sidebar-left {
  .puam-layouts-content {
    .container {
      @media (min-width: $bp-med-2) {
        padding-right: 0;
      }
    }

    +.puam-layouts-sidebar {
      .container {
        @media (min-width: $bp-med-2) {
          padding-left: 0;
        }
      }
    }
  }

  .puam-layouts-sidebar {
    @include span-columns(12);

    @media all and (min-width: $bp-med-2) {
      @include span-columns(4);
    }
  }
}

.puam-sidebar-right {
  .puam-layouts-sidebar {
    .container {
      @media (min-width: $bp-med-2) {
        padding-right: 0;
      }
    }

    +.puam-layouts-content {
      .container {
        @media (min-width: $bp-med-2) {
          padding-left: 0;
        }
      }
    }
  }
}


.puam-event {
  .puam-layouts-title {
    @include span-columns(12);

    @media all and (min-width: $bp-med-2) {
      @include span-columns(8);

      .container {
        padding-right: 0;
      }
    }
  }

  .puam-layouts-breadcrumbs {
    @include span-columns(12);

    @media all and (min-width: $bp-med-2) {
      @include span-columns(4);

      .container {
        padding-left: 0;
        text-align: right;
      }
    }
  }

  .puam-layouts-image {
    @include span-columns(12);

    @media all and (min-width: $bp-small-2) {
      @include span-columns(6);
      margin-right: 0;
    }

    @media all and (min-width: $bp-med-2) {
      @include span-columns(3);
    }
  }

  .puam-layouts-meta {
    @include span-columns(12);

    @media all and (min-width: $bp-small-2) {
      @include span-columns(6);
      margin-right: 0;

      .container {
        padding-left: 0;
      }
    }

    @media all and (min-width: $bp-med-2) {
      @include span-columns(3);

      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media all and (min-width: $bp-xxl) {
      @include span-columns(2);
      clear: none;
    }
  }

  .puam-layouts-description {
    @include span-columns(12);
    clear: both;

    @media all and (min-width: $bp-med-2) {
      @include span-columns(6);
      clear: none;
    }

    @media all and (min-width: $bp-xxl) {
      @include span-columns(7);
      clear: none;
    }
  }
}

.puam-threecol {
  .puam-layouts-column1,
  .puam-layouts-column2,
  .puam-layouts-column3 {
    @include span-columns(12);

    @media all and (min-width: $bp-med-2) {
      @include span-columns(4);
    }
  }
}

.puam-layouts-footer {
  @include span-columns(12);
}

.row {
  clear: both;
}
