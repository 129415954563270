/* BUTTONS */
.btn {
  display: inline-block;
  background: transparent;
  border-bottom: 1px solid transparent;
  color: $black;
  line-height: 1;
  padding: 0;
  border: 0;
  text-align: center;
  letter-spacing: $letter-spacing-link;
  font-family: $font-secondary;
  font-size: $font-size-med;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 900;
  @include transition( color 0.5s ease-out, background-color 0.5s ease-out);

  &::after {
    font-family: "puamicons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 1.2em;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1.2em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin: 0;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e807';
    transition: transform .35s ease-in-out;
    width: 1.5rem;
  }

  &:hover, &:focus {
    background: transparent;
    border-bottom-color: $gray-drk1;
    text-decoration: none;

    &::after {
      //content: '\e806';
      transform: rotate(90deg) translateX(5%);
      transition: transform .35s ease-in-out;
    }
  }

  &.disabled {
    background: transparent;
    color: $gray;
    text-decoration: line-through;

    &::after {
      content: none;
      width: 0;
    }
  }

  &.btn--calendar {
    &::after {
      content: '\e800';
      width: 2.2rem;
    }

    &:hover, &:focus {

      &::after {
        content: '\f273';
        margin-left: 0.75px;
        transform: none;
      }
    }

    &.disabled {
      text-decoration: line-through;

      &::after {
        content: none;
      }
    }
  }

  &.btn--action {
    align-items: center;
    background: $black;
    color: $white;
    display: inline-flex;
    min-height: 44px;
    min-width: 44px;
    padding: $pad-half;

    &:hover, &:focus {
      background: $primary-brand;
      color: $white;
    }

    &.disabled {
      background: $gray;
      color: $white;
      padding: 0.69rem $pad;
      text-decoration: line-through;
    }

    &::after {
      content: '';
      width: 0;
    }

    &.btn--secondary {
      background: $white;
      color: $black;
      border: 2px solid $black;

      &:hover,
      &:focus {
        background: $white;
        color: $black;
        border: 2px solid $primary-brand;
        @include transition(border-color 0.5s ease-out);
      }

      &.disabled {
        background: $white;
        color: $gray;
        border: 2px solid $gray;
      }
    }
  }

  &.btn--add {
    &::after {
      content: '\e802';
      transform: none;
    }
  }

  &.btn--remove {
    &::after {
      content: '\e803';
      transform: none;
    }
  }
}

.btn--small {
  font-size: 0.8rem;
}

.btn--large {
  font-size: 1.4rem;

  &::after {
    width: 2.4rem;
  }
}

.btn--mfab {
  align-items: center;
  background: #f8e914;
  color: #480c47;
  display: inline-flex;
  font-size: 1.4rem;
  margin-inline-end: 1rem;
  margin-block-end: 1rem;
  min-height: 44px;
  min-width: 44px;
  padding: .5rem 1rem;
  text-transform: uppercase;

  &:hover,
  &:focus {
    background: #480c47;
    color: #f8e914;
  }
}

//Nodequeue Links
.toggle-add, .toggle-remove {
  display: inline-block;
  background: transparent;
  color: $black;
  line-height: 1;
  padding: 0;
  border: 0;
  text-align: center;
  letter-spacing: $letter-spacing-link;
  font-family: $font-secondary;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 900;

  &::after {
    font-family: "puamicons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 1.2em;

    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1.2em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin: 0;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    transition: transform .35s ease-in-out;
  }

  &:hover,
  &:focus {
    transform: none;
  }
}

.toggle-add {
  @extend .btn.btn--add;
}

.toggle-remove {
  @extend .btn.btn--remove;
}

.feedbackCollect-container {
  bottom: 5vw;
  right: 5vw;
  position: fixed;
  z-index: 1;
}

.btn--feedback-collect {
  background: $error;
  color: $black;
  padding: .5em;

  &:hover,
  &:focus {
    background: $black;
    color: $error;
  }
}
