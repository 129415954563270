/* Hero */
.hero {
  display: inline-block;
  position: relative;
  width: 100%;

  .slide {
    @media all and (min-width: $bp-med-2) {
      max-height: 40vw;
    }
  }
}

.slide {
  display: inline-block;
  position: relative;
  width: 100%;

  img {
    display: block;
  }

  .overlay-copy {
    bottom: auto;
    left: auto;
    padding: 45px 20px 10px;
    position: relative;
    right: auto;

    @media all and (min-width: $bp-med-2) {
      bottom: 30px;
      left: 30px;
      padding: 0;
      position: absolute;
      right: 60px;
      z-index: 10;
    }
  }

  .gradient {
    opacity: 0;

    @media all and (min-width: $bp-med-2) {
      opacity: 1;
    }
  }

  .slide__content:hover h2,
  .slide__content:focus h2 {
    @include transition(opacity, 0.5s, ease-in);
  }
}

.overlay-copy {
  font-size: 0.8em;

  @media all and (min-width: $bp-med-2) {
    font-size: 1.2em;
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .headline {
    font-weight: 500;
    margin: 0;
  }

  .sub-headline {
    font-size: 1.17em;
    font-weight: 400;
    line-height: 1.15em;
    margin: $pad-quarter 0;

    em,
    i {
      font-style: italic;
    }

    strong,
    b {
      font-weight: 500;
    }

    a {
      text-decoration: underline;
    }
  }

  .meta {
    font-weight: 300;
    margin: 0;
  }

  &.overlay-copy--options {
    .headline {
      font-size: 1.65em;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.text--light {
  color: $black;

  @media all and (min-width: $bp-med-2) {
    color: $white;
  }

  h2 {
    color: $black;

    @media all and (min-width: $bp-med-2) {
      color: $white;
    }
  }
}

.text--dark {
  @include transition(opacity, 0.5s, ease-in);
  color: $black;

  h2 {
    @include transition(opacity, 0.5s, ease-in);
    color: $black;

    &:hover,
    &:focus {
      @include transition(opacity, 0.5s, ease-in);
    }
  }
}

.text--hover {
  .gradient {
    opacity: 0;
  }

  .overlay-copy {
    opacity: 1;
    @include transition(opacity, 0.5s, ease-in);

    @media all and (min-width: $bp-med-2) {
      opacity: 0;
    }
  }

  @media all and (min-width: $bp-med-2) {
    &:hover,
    &:focus {
      .gradient,
      .overlay-copy {
        opacity: 1;
      }
    }
  }
}

/* Micro-slider */
.micro-slider {
  display: inline-block;
  width: 100%;

  &.micro-slider--right {
    padding: 0 20px 20px;

    @media all and (min-width: $bp-med-2) {
      border-left: 2px solid $gray-lgt2;
      float: right;
      padding: 0 20px;
    }
  }

  &.micro-slider--left {
    padding: 0 20px 20px;

    @media all and (min-width: $bp-med-2) {
      border-right: 2px solid $gray-lgt2;
      float: right;
      padding: 0 20px;
    }
  }

  &.micro-slider--single {
    &.micro-slider--right {
      padding: 0 20px 20px;

      @media all and (min-width: $bp-med-2) {
        border-left: 4px solid $gray-lgt2;
        float: right;
        padding: 0 20px;
      }
    }

    &.micro-slider--left {
      padding: 0 20px 20px;

      @media all and (min-width: $bp-med-2) {
        border-right: 4px solid $gray-lgt2;
        padding: 0 20px;
      }
    }
  }

  &--galleries-cards,
  &--news-cards {
    position: relative;

    .slick-slide {
      padding: 0 0 20px;

      @media all and (min-width: $bp-med-2) {
        padding: 0 10px;
      }
    }

    .lead {
      font-family: $font-secondary;
      font-size: $font-size-smallest;
      font-weight: 700;
      text-transform: uppercase;
    }

    .title {
      border-bottom: 1px solid $primary-brand;
      display: inline;
      line-height: 1.4;

      &::after {
        display: none !important;
      }

      + p {
        margin-top: $space-extra;
      }
    }

    p {
      font-size: $font-size-small-2;
    }
  }

  .slide__content {
    img {
      border-bottom: 4px solid transparent;
    }

    .mask:hover ~ * ,
    .mask:focus ~ *  {
      img {
        border-bottom: 4px solid $primary-brand;
        transition: border-bottom .35s ease-in-out;
      }

      > .title {
        border-bottom-color: $gray-drk1;
      }
    }

    .container {
      padding: 20px 0;

      @media all and (min-width: $bp-med-2) {
        padding: 20px;
      }
    }
  }

  .date-repeat-instance {
    display: inline;
  }
}

/* Slick Customizations */
.slick {
  a {
    display: inline-block;
    cursor: pointer;
    width: 100%;

    .title {
      padding: $pad 0;
    }
  }

  img {
    width: 100%;
  }
}

.slick-slider,
.slick__arrow {
  .slick-prev {
    left: 0;
    &:before {
      content: "\e810";
    }
  }

  .slick-next {
    right: 0;
    &:before {
      content: "\e811";
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    &:before {
      color: $black;
      font-family: "puamicons";
      font-size: 1rem;
    }
  }
}

.slick-slide {
  height: auto;
}

.controls {
  margin-bottom: 5px;

  @media (min-width: $bp-med-2) {
    padding: 0 10px;
  }

  .btn {
    padding: 0;
    //margin-right: $pad-three-quarter;
  }

  @extend .ul--horizontal;
  display: inline-block;
  width: 100%;

  li {
    &:last-child {
      float: right;
    }

    &:first-child {
      float: left;
    }
  }
}

.slick-slider {
  .slick-prev {
    left: 0;

    &:before {
      content: "\e808";
    }
  }

  .slick-next {
    right: 0;

    &:before {
      content: "\e807";
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 1;

    &:before {
      color: $black;
      font-family: "puamicons";
      font-size: 2rem;
    }
  }
}

.slick__arrow {
  position: absolute;
  right: 30px;
  top: 10px;

  .slick-arrow {
    height: 44px;
    min-width: 44px;
    padding: 0;

    &:hover,
    &:focus {
      &::before {
        color: $black;
      }
    }

    &::before {
      color: $white;
    }

    &.slick-disabled {
      background: $gray-drk1;
      cursor: not-allowed;

      &:hover,
      &:focus {
        &::before {
          color: $white;
        }
      }
    }
  }
}
