.accordion {
  clear: both;
  display: block;
  margin: 0 0 $pad;
  padding: 0;
  width: 100%;

  .trigger {
    border-bottom: 4px solid $gray-lgt1;
    color: $black;
    cursor: pointer;
    display: block;
    font-family: $font-secondary;
    font-size: $font-size-med-3;
    margin: 0 0 $pad-three-quarter;
    position: relative;
    padding: 0 0 $pad-half;
    text-decoration: none;
    // text-transform: uppercase;
    width: 100%;

    &:hover,
    &:focus {
      color: $primary-brand;
      text-decoration: none;
    }
  }

  .content {
    display: none;
    overflow: hidden;
    padding: 0 1.8rem;

    &.active {
      display: block;
      overflow: visible;
    }
  }
}

// Peter C. Bunnell Tribute overrides
.page-node-1795691 .accordion {
  .content {
    display: block;
    max-height: 240px;

    &.active {
      max-height: inherit;
    }
  }
}
