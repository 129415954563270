.pearlman-logo a {
  background: $black;
  border-bottom: none !important;
  clear: both;
  color: $white;
  display: inline-block;
  font-size: $font-size-aa;
  margin: .5rem 0;
  padding: 12px;
  text-align: center;
  text-transform: uppercase;

  &:hover,
  &:focus {
    border-bottom: none !important;

    * {
      border-bottom: 1px solid $white;
    }
  }

  .pearlman-yellow {
    color: #d3e426;
  }

  strong {
    color: $white;
  }

  .pearlman-blue {
    color: #6d9dbb;
  }
}

.highlights-pearlman {
  &.view-highlights {
    background: $white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
    margin-bottom: 1em;
    padding: .5em 1em;
    overflow: hidden;
  }

  >.view-content {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;

    h3 {
      padding-bottom: 1em;
      width: 100%;
    }

    .views-row {
      align-self: center;
      margin-bottom: 2em;
      text-align: center;
      width: 25%;
      @media all and (max-width: $bp-small) {
        margin-bottom: 1em;
        width: 100%;
      }
    }
  }
  &.object {
    .column {
      background: $white;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
      margin-bottom: 1em;
      padding: .5em 1em;
      overflow: hidden;
    }

    .page__title {
      display: none;
      visibility: hidden;
    }

    .field-name-field-teaser,
    .field-name-body,
    .field-content {
      img {
        max-width: 420px;
        height: auto !important;
        max-height: 420px;
        width: auto !important;
      }
    }

    .intro-toggle a {
      display: none;
      @media all and (max-width: $bp-small) {
        display: block;
      }
    }

    @media all and (max-width: $bp-small) {
      .intro-body {
        width: 100%;
        display: none;
      }
    }

    .intro-block {
      display: block !important;
    }
  }
}

.menu-pearlman {
  background: $black;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0 calc((100vw - 980px)/2) 1em;
  @media (max-width: $bp-small) {
    flex-wrap: wrap;
  }

  li {
    align-self: center;
    flex-grow: 1;
    //margin: .5em 0;
    //padding: 1em;
    text-align: center;
    width: 29%;
    @media (min-width: $bp-small + 1) and (max-width: $bp-med) {
      width: 50%;
    }
    @media (max-width: $bp-small) {
      width: 100%;
    }

    a {
      background: $black;
      color: #d3e426;
      font-family: $font-secondary;
      font-weight: 900;
      letter-spacing: 0.1em;
      padding: .5em 0;
      text-transform: uppercase;
      text-decoration: none;
      width: 95%;

      &:hover,
      &:focus {
        color: #6d9dbb;
      }
    }
  }
}
