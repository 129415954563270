//
// Initialization partial
//
// To make it easier to use all variables and mixins in any Sass file in this
// theme, each .scss file has a @import "init" declaration. And this _init.scss
// file is in charge of importing all the other partials needed for the theme.
//
// This initialization partial is organized in this way:
// - First we set any shared Sass variables.
// - Next we import Compass plug-ins (Sass mixin libraries).
// - Last we define our custom mixins for this theme.
//

// =============================================================================
// Variables
// =============================================================================

//Colors
$primary-brand: #ff6100;
$primary-brand-drk1: darken($primary-brand, 5%);
$primary-brand-drk2: darken($primary-brand, 10%);
$primary-brand-drk3: darken($primary-brand, 15%);
$primary-brand-lgt1: lighten($primary-brand, 5%);
$primary-brand-lgt2: lighten($primary-brand, 10%);
$primary-brand-lgt3: lighten($primary-brand, 15%);

$orange-lgt1: #fef7f3;

$gray-lgt3: #f9f9f9; // Formerly $gray-light
$gray-lgt2: #eee; // Formerly $gray-light-2
$gray-lgt1: #ddd; // Formerly $gray-light-3
$gray: #808080;
$gray-drk1: #666; // Formerly $gray-med
$gray-drk2: #333; // Formerly $gray-dark
$gray-drk3: #131313; // Formerly $gray-dark-2

$white: #fff;
$black: #000;

$dim: rgba(0, 0, 0, 0.5);

$error: #d9534f;
$valid: #089e00;
$warning: #f0ad4e;
$information: #5bc0de;

//Typography
$font: "Roboto", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
$font-secondary: "Lato", Georgia, Times, "Times New Roman", serif;

$font-size-smallest: 0.6666em; //12px
$font-size-small: 0.7777em; //14px
$font-size-small-2: 0.8888em; //16px
$font-size-med: 1em; //18px
$font-size-med-2: 1.0555em; //19px
$font-size-aa: $font-size-med-2; //19px, WCAG AA Compliant when bold and $primary-brand
$font-size-med-3: 1.1111em; //20px
$font-size-large: 1.4444em; //26px
$font-size-largest: 1.9444em; //35px

$letter-spacing-link: 0.1em;
$letter-spacing-copy: 0.025em;

//Layout
$max-width: 72em;

//Defaults
$space: 1rem;
$space-small: $space * 0.8888;
$space-extra: $space * 1.1111;
$space-and-half: $space * 1.5;
$space-double: $space * 2;
$space-triple: $space * 3;
$space-quad: $space * 4;
$space-half: $space/2;
$pad: 1rem;
$pad-and-half: $pad * 1.5;
$pad-double: $pad * 2;
$pad-half: $pad/2;
$pad-quarter: $pad/4;
$pad-three-quarter: $pad * 0.75;

//Borders
$border-med: 3px;
$border-thick: 7px;

//Breakpoints
$bp-small: 24em;
$bp-small-2: 29.75em;
$bp-small-3: 39.8em;
$bp-med: 46.8em;
$bp-med-2: 48em;
$bp-large: 50em;
$bp-large-2: 66em;
$bp-xl: 73em;
$bp-xxl: 90em; // x 16 = 1440
$bp-xxxl: 106em; // x 16 = 1710
$bp-menu: 1100px;

// =============================================================================
// Partials to be shared with all .scss files.
// =============================================================================

// Add Compass' IE and vendor prefix support variables.
//@import "compass/support";
//// Better than Drupal's clearfix.
//@import "compass/utilities/general/clearfix";
//// See http://compass-style.org/help/tutorials/spriting/
//@import "compass/utilities/sprites";
//// Use one CSS3 mixin instead of multiple vendor prefixes.
//@import "compass/css3";
//// Helps set up a vertical rhythm.
//@import "compass/typography/vertical_rhythm";
//// Add the Zen Grids responsive layout mixins.
//@import "zen";

// Now we add our custom helper mixins.
@import "mixins";
