/*------------------------------------*\
    $FORMS
\*------------------------------------*/
form ol,
form ul {
  list-style: none;
  margin-left: 0;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 1em 0 0;
}

legend {
  -webkit-padding-start: 0;
  -webkit-padding-end: 0;
  font-weight: bold;
  line-height: 1.2;
  font-size: 1.5em;
}

label {
  display: block;
  padding-bottom: $space-half;
  font-weight: bold;
  color: $gray-drk3;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

select {
  border: 2px solid $gray-drk3;
}

input,
textarea {
  width: auto;
  border: 2px solid $gray-drk3;
  padding: $pad-half;
  @include transition( border-color 0.25s ease-out);

  &:focus {
    border: 2px solid $primary-brand;
  }

  &.disabled {
    border: 2px solid $gray-lgt1;

    &:focus {
      border: 2px solid $gray-lgt1;
    }
  }
}

input[type="text"],
input[type="search"],
input[type="url"],
input[type="number"],
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

button,
input[type="submit"] {
  background-color: $black;
  color: $white;
  line-height: 1;
  padding: $pad-three-quarter;
  border: 0;
  text-align: center;
  letter-spacing: $letter-spacing-link;
  font-family: $font-secondary;
  font-size: $font-size-med;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 900;
  @include transition( color 0.5s ease-out, background-color 0.5s ease-out);
  width: auto;
  -webkit-appearance: button;
  border-radius: 0;

  &:hover,
  &:focus {
    background-color: $primary-brand;
    cursor: pointer;
  }
}

input[type="checkbox"],
input[type="radio"] {
  width: auto;
  margin-right: 0.3em;
}

input[type="search"] {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea,
input {
  @include placeholder {
    color: $gray;
  }
}

/* Validation */
.error {
  input,
  textarea {
    border-color: $error;

    &:focus {
      border: 2px solid $primary-brand;
    }
  }
}

.valid {
  input,
  textarea {
    border-color: $valid;

    &:focus {
      border: 2px solid $primary-brand;
    }
  }
}

/*------------------------------------*\
    $SPECIFIC FORMS
\*------------------------------------*/

.search {

  .container-inline {
    div {
      display: inline-block;
    }

    label {
      display: inline-block;
    }
  }
}

//Form Field Container
.field-container {
  margin-bottom: $space;
}

.inline-form {
  fieldset, .inline-container {
    position: relative;
  }

  input[type=submit], button, .btn {
    font-size: $font-size-small-2;
    padding: 0.65rem 1.3rem;
    background: $gray-drk2;
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 1;
    width: auto;

    &:hover,
    &:focus {
      background: $gray;
      color: $white;
    }
  }
}

.form-required {
  color: $error;
}
