/*------------------------------------*\
    $FOOTER
\*------------------------------------*/
.footer__address {
  font-size: $font-size-small-2;
}

.footer__address--csz {
  @include font-light;
}

.footer__address--org {
  font-weight: 500;
}

.footer {
  padding: 30px;
  clear: both;
  overflow: hidden;
  background: $black;
  color: $white;
  line-height: 1.2em;

  a {
    color: $white;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $primary-brand;
    }
  }

  &--left {
    @include span-columns(12);

    .footer__container:last-child {
      margin: 0;
    }

    @media all and (min-width: $bp-med) {
      @include span-columns(6);
    }

    .nav-footer {
      font-size: $font-size-small;
    }
  }

  &--right {
    @include span-columns(12);
    margin-top: $space;

    .nav-footer {
      font-size: $font-size-small;
    }

    @media all and (min-width: $bp-med) {
      @include span-columns(6);
      margin-top: 0;
    }
  }

  &__container {
    margin: 0 0 $pad;
    overflow: hidden;
    width: 100%;
  }

  &__container--right {
    & > * {
      /* all children of this div also float right */
      float: left;

      @media all and (min-width: $bp-med) {
        float: right;
      }
    }
  }
}

.footer__tagline {
  text-transform: uppercase;
}

//Social Share
.social {
  display: inline-block;
  font-size: $font-size-large;
  margin-bottom: $space-half;

  li {
    float: left;
    margin-right: $space-half;

    &:first-child {
      margin-left: 0;

      [class^="icon-"]:before,
      [class*=" icon-"]:before {
        margin-left: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: block;
    padding-left: $pad-half;
    padding-right: $pad-half;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:hover,
    &:focus {
      color: $primary-brand;
    }

    span {
      display: inline-block;
      text-indent: -9999px;
    }
  }
}

//Footer Nav
.nav-footer {
  font-family: $font-secondary;
  @include font-black;
  list-style-type: none;
  margin-bottom: $space-half;
  text-transform: uppercase;
  padding-left: 0;

  li {
    border: 0;
    float: left;

    &:first-child {
      a {
        padding-left: 0;
      }
    }

    &:last-child {
      a {
        padding-right: 0;
      }
    }
  }

  a {
    display: block;
    padding: $pad-half;
  }
}

//Constant Contact
.enews {
  label {
    color: $white;
  }

  input {
    width: auto;
  }

  .form-item-cc-email-1,
  #mc_embed_signup_scroll > div:not(:first-child) {
    display: inline;
  }

  .form-text,
  input:-webkit-autofill {
    background: $gray-drk3;
    border: 2px solid $white;
    color: $white;
    font-size: 0.875em;
    max-width: 170px;

    &:focus {
      border: 2px solid $primary-brand;
      color: $white;
    }

    .form-required {
      color: #c00;
    }
  }

  .form-submit {
    background: $white;
    border: 0;
    color: $gray-drk3;
    display: inline;
    font-weight: 900;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background: $primary-brand;
      border: 0;
    }
  }
}

//Copyright
.copyright {
  @include font-light;
  font-size: $font-size-small;
  margin-top: $pad;

  @media all and (min-width: $bp-med) {
    margin-top: $pad-three-quarter;
  }
}

.copyright--company {
  @include font-bold;
}

.footer__phone {
  font-size: $font-size-small-2;
  @include font-bold;
  text-align: left;
}
