html {
  background: $black;
}

body {
  -webkit-text-size-adjust: 100%;
  background: $white;
  color: $black;
  font: 300 18px/1.5 $font;
  letter-spacing: $letter-spacing-copy;
  margin: 0;
  overflow-x: hidden;

  &.navbar-administration {
    padding-top: 39px !important;

    &.navbar-horizontal.navbar-tray-open {
      padding-top: 79px !important;
    }
  }
}

.wrapper {
  width: 100%;
  height: auto;
  display: inline-block;
  background: $white;
  padding: 30px;

  &.wrapper--gray-lgt3 {
    background: $gray-lgt3;
  }

  &.wrapper--gray-lgt2 {
    background: $gray-lgt2;
  }

  &.wrapper--gray-lgt1 {
    background: $gray-lgt1;
  }

  &.wrapper--gray {
    background: $gray;
  }

  &.wrapper--gray-drk1 {
    background: $gray-drk1;
  }

  &.wrapper--gray-drk2 {
    background: $gray-drk2;
  }

  &.wrapper--gray-drk3 {
    background: $gray-drk3;
  }

  &.wrapper--black {
    background: $black;
  }
}
